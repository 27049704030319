// src/components/ConversationList.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Avatar from './Avatar/Avatar';

const ConversationList = ({ conversations, selectedConversation, onSelectConversation }) => {
  const [userDetails, setUserDetails] = useState({});
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State to track search input
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (currentUser) {
      conversations.forEach(async (conversation) => {
        const userId = conversation.users.find((uid) => uid !== currentUser.uid);
        if (!userDetails[userId]) {
          try {
            const userRef = doc(db, 'users', userId);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
              setUserDetails((prevDetails) => ({
                ...prevDetails,
                [userId]: userSnap.data(),
              }));
            }
          } catch (err) {
            setError(err);
          }
        }
      });
    }
  }, [conversations, currentUser, userDetails]);

  if (!currentUser) {
    return null;
  }

  if (error) {
    console.error("Firestore error:", error);
    return <p>Something went wrong.</p>; 
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredConversations = conversations.filter((conversation) => {
    const otherUserId = conversation.users.find((uid) => uid !== currentUser.uid);
    const otherUser = userDetails[otherUserId];
    return otherUser?.firstName?.toLowerCase().includes(searchTerm);
  });

  return (
    <aside className="message-sidebar">
      <div className="message-search-box">
        <input 
          type="text" 
          className="w-full p-3 h-10 border border-gray-300 rounded-xl" 
          placeholder="Buscar Chat" 
          onChange={handleSearchChange}
        />
      </div>
      <div className="conversation-list">
        {filteredConversations.map((conversation) => {
          const otherUserId = conversation.users.find((uid) => uid !== currentUser.uid);
          const otherUser = userDetails[otherUserId];

          return (
            <div
              key={conversation.id}
              className={`conversation ${selectedConversation?.id === conversation.id ? 'selected' : ''}`}
              onClick={() => onSelectConversation(conversation)}
            >
              <div className="avatar-container flex-shrink-0 mr-2">
                <Avatar 
                  src={otherUser?.avatar}
                  alt={`${otherUser?.firstName || 'User'}'s Avatar`}
                  name={otherUser?.firstName}
                />
              </div>
              <div className="conversation-info">
                <h5>{otherUser?.firstName || 'Unknown User'}</h5>
                <p>{conversation.initialMessage}</p>
              </div>
            </div>
          );
        })}
      </div>
    </aside>
  );
};

export default ConversationList;

