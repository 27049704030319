// src/components/Avatar/Avatar.js
import React from 'react';

const Avatar = ({ src, alt, name, large }) => {
  // Set the class name based on the 'large' prop
  const avatarClass = large ? "w-40 h-40" : "w-10 h-10";

  // CSS classes to prevent shrinking and guarantee fixed sizing
  const fixedSizeClass = "flex-shrink-0"; // Prevents flex items from shrinking

  if (src) {
    // If there's an image source, display the image with the respective size
    return <img src={src} alt={alt} className={`${avatarClass} ${fixedSizeClass} rounded-full mr-2`} />;
  }

  // Calculate the initial and adjust font size and flexbox alignment for the initial's avatar
  const initial = name ? name.charAt(0) : '?';
  const initialFontSize = large ? "text-4xl" : "text-lg"; // Larger text for large avatar

  return (
    <div className={`${avatarClass} ${fixedSizeClass} bg-pink-500 rounded-full mr-0 md:mr-2 flex items-center justify-center text-white ${initialFontSize}`}>
      {initial}
    </div>
  );
};

export default Avatar;