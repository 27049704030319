// src/components/ParceirosDaRedeManagement.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const ParceirosDaRedeManagement = () => {
  const [partners, setPartners] = useState([]);
  const [newPartner, setNewPartner] = useState({ link: '', logo: '' });

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'content', 'parceiros');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().partners) {
        setPartners(docSnap.data().partners);
      } else {
        console.log("No such document or empty partners array!");
      }
    };

    fetchData();
  }, []);

  const handleAddPartner = async (e) => {
    e.preventDefault();
    const updatedPartners = [...partners, newPartner];
    const docRef = doc(db, 'content', 'parceiros');
    try {
      await setDoc(docRef, { partners: updatedPartners }, { merge: true });
      setPartners(updatedPartners);
      setNewPartner({ link: '', logo: '' });
      alert('Partner added successfully!');
    } catch (error) {
      console.error('Error adding partner', error);
      alert('Error adding partner.');
    }
  };

  const handleUpdatePartner = async (index, updatedPartner) => {
    const updatedPartners = partners.map((p, i) => i === index ? updatedPartner : p);
    const docRef = doc(db, 'content', 'parceiros');
    try {
      await setDoc(docRef, { partners: updatedPartners }, { merge: true });
      setPartners(updatedPartners);
      alert('Partner updated successfully!');
    } catch (error) {
      console.error('Error updating partner', error);
      alert('Error updating partner.');
    }
  };

  const handleDeletePartner = async (index) => {
    const updatedPartners = partners.filter((_, i) => i !== index);
    const docRef = doc(db, 'content', 'parceiros');
    try {
      await setDoc(docRef, { partners: updatedPartners }, { merge: true });
      setPartners(updatedPartners);
      alert('Partner deleted successfully!');
    } catch (error) {
      console.error('Error deleting partner', error);
      alert('Error deleting partner.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-xl font-semibold text-gray-800 mb-6">Administrar Parceiros da Rede</h1>
      <form onSubmit={handleAddPartner} className="mb-6">
        <input type="text" placeholder="Link" value={newPartner.link}
          onChange={e => setNewPartner({ ...newPartner, link: e.target.value })}
          className="border p-2 mr-2" />
        <input type="text" placeholder="Logo URL" value={newPartner.logo}
          onChange={e => setNewPartner({ ...newPartner, logo: e.target.value })}
          className="border p-2 mr-2" />
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Add Partner</button>
      </form>
      {partners.map((partner, index) => (
        <form key={index} onSubmit={(e) => {
          e.preventDefault();
          handleUpdatePartner(index, partner);
        }} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Link:
            </label>
            <input
              type="text"
              value={partner.link}
              onChange={e => handleUpdatePartner(index, { ...partner, link: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Logo URL:
            </label>
            <input
              type="text"
              value={partner.logo}
              onChange={e => handleUpdatePartner(index, { ...partner, logo: e.target.value })}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <button
            type="button"
            onClick={() => handleDeletePartner(index)}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Delete
          </button>
        </form>
      ))}
    </div>
  );
};

export default ParceirosDaRedeManagement;
