// src/components/MessageArea.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, orderBy, getDocs, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Avatar from './Avatar/Avatar';

const MessageArea = ({ conversation }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const auth = getAuth();
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchMessages = async () => {
      const messagesRef = collection(db, "conversations", conversation.id, "messages");
      const q = query(messagesRef, orderBy("createdAt", "asc"));
      const querySnapshot = await getDocs(q);
      let fetchedMessages = [];
      let userIds = new Set();

      querySnapshot.forEach((docSnapshot) => {
        fetchedMessages.push(docSnapshot.data());
        userIds.add(docSnapshot.data().sender);
      });

      userIds.delete(currentUser.uid);  // Remove current user's ID

      const userDetails = {};
      for (let userId of userIds) {
        const userRef = doc(db, 'users', userId);
        const userSnap = await getDoc(userRef);
        userDetails[userId] = userSnap.exists() ? userSnap.data() : { avatar: null, firstName: 'Unknown' };
      }

      // Fetch current user details
      const currentUserRef = doc(db, 'users', currentUser.uid);
      const currentUserSnap = await getDoc(currentUserRef);
      if (currentUserSnap.exists()) {
        userDetails[currentUser.uid] = currentUserSnap.data();
      }

      setUserDetails(userDetails);
      setMessages(fetchedMessages);
    };

    if (currentUser) {
      fetchMessages();
    }
  }, [currentUser, conversation.id]);

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      const messageRef = collection(db, "conversations", conversation.id, "messages");
      await addDoc(messageRef, {
        text: newMessage,
        sender: currentUser.uid,
        createdAt: serverTimestamp()
      });

      setMessages(prevMessages => [...prevMessages, {
        text: newMessage,
        sender: currentUser.uid,
        createdAt: null  // This is for immediate feedback, timestamp will be null until refreshed
      }]);
      setNewMessage("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <main className="chat-area pb-5 md:pb-0">
      <div className="messages-container">
        {messages.map((message, index) => {
          const isCurrentUser = message.sender === currentUser.uid;
          const userDetail = userDetails[message.sender] || {};

          return (
            <div key={index} className={`message-wrapper ${isCurrentUser ? 'right' : 'left'}`}>
              {!isCurrentUser && (
                <Avatar
                  src={userDetail.avatar}
                  alt={`${userDetail.firstName || 'User'}'s Avatar`}
                  name={userDetail.firstName}
                />
              )}
              <div className={`chat-message ${isCurrentUser ? 'right' : 'left'}`}>
                <p>{message.text}</p>
              </div>
              {isCurrentUser && (
                <Avatar
                  src={userDetails[currentUser.uid]?.avatar}
                  alt="Your Avatar"
                  name={userDetails[currentUser.uid]?.firstName || currentUser.displayName}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="message-input">
        <input 
          type="text" 
          placeholder="Digite sua mensagem aqui..." 
          className="w-full p-0 h-10 border border-gray-300 rounded-xl"
          value={newMessage} 
          onChange={(e) => setNewMessage(e.target.value)} 
          onKeyPress={handleKeyPress}  // Add this line
        />
        <button onClick={handleSendMessage}>&#8594;</button> {/* Arrow symbol as button text */}
      </div>
    </main>
  );
};

export default MessageArea;

