import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import H1 from './H1/H1';
import './ParceirosDaRede.css'; // Import the CSS file for styling

const ParceirosDaRede = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const fetchPartners = async () => {
      const docRef = doc(db, 'content', 'parceiros');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().partners) {
        setPartners(docSnap.data().partners);
      } else {
        console.log("No partners data found!");
      }
    };

    fetchPartners();
  }, []);

  return (
    <div className='parceiros-da-rede'>
      <div className='max-w-7xl mx-auto'>
        <div className='title-area'>
          <H1 text="Parceiros da Rede" bgColor="grey" fontColor="gray" />
        </div>
        <div className='px-4 md:px-0 logos-area max-w-7xl mx-auto flex flex-wrap justify-center'>
          {partners.map((partner, index) => (
            <a key={index} href={partner.link} target="_blank" rel="noreferrer noopener" className="m-1">
              <img src={partner.logo} alt={`Logo ${index + 1}`} className="h-24" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ParceirosDaRede;
