// src/components/EntrepreneursGrid.js
import React from 'react';
import EntrepreneurSkeleton from './EntrepreneurSkeleton';
import EntrepreneurCard from './EntrepreneurCard/EntrepreneurCard';
import Button from './Button/Button';

const EntrepreneursGrid = ({ entrepreneurs, loading, hasMore, loadMoreEntrepreneurs, onShowDetails }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {loading ? (
        Array.from({ length: 10 }).map((_, index) => (
          <EntrepreneurSkeleton key={index} />
        ))
      ) : (
        entrepreneurs.map(entrepreneur => (
          <EntrepreneurCard 
            key={entrepreneur.id} 
            entrepreneur={entrepreneur} 
            onShowDetails={onShowDetails}
          />
        ))
      )}
      {!loading && hasMore && (
        <div className="flex justify-center mt-4 w-full col-span-full">
          <Button text="Carregar mais" variant="solid" onClick={loadMoreEntrepreneurs} />
        </div>
      )}
    </div>
  );
};

export default EntrepreneursGrid;
