// src/components/LoginForm.js
import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import Button from './Button/Button';
import H2 from './H2/H2';

const LoginForm = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoginError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      onLoginSuccess(userCredential.user.uid);
      navigate('/home-logado');
    } catch (error) {
      let errorMessage = 'Erro ao fazer login.';
      if (error.code === 'auth/user-not-found') {
        errorMessage = 'Usuário não encontrado.';
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Senha incorreta.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'E-mail inválido.';
      }
      setLoginError(errorMessage);
    }
  };

  return (
    <div className="flex justify-center items-center bg-blue-600 bg-cover bg-no-repeat bg-center" style={{ backgroundImage: "url(/img/bg-cadastro.svg)" }}>
      <div className="bg-white mt-20 mb-20 rounded-xl p-14 w-full max-w-lg mx-4">
        <H2 text="Fazer login" textColor="purple" />
        <p className="mb-6 text-lg text-gray-700">Novo por aqui? <Link to="/criar-conta" className="text-blue-800 hover:underline">Crie uma conta</Link></p>

        <form onSubmit={handleSubmit} className="flex flex-col gap-5">
          <div className="field">
            <label htmlFor="email" className="block text-lg text-gray-700 mb-2">E-mail</label>
            <input id="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full p-3 border border-gray-300 rounded-xl" />
          </div>
          <div className="field">
            <label htmlFor="password" className="block text-lg text-gray-700 mb-2">Senha</label>
            <input id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="w-full p-3 border border-gray-300 rounded-xl" />
          </div>

          {loginError && <p className="text-red-500 text-lg mt-2">{loginError}</p>}

          <div className="flex justify-between items-center mt-4">
            <Link to="/esqueci-a-senha" className="text-blue-800 text-lg hover:underline">Esqueci minha senha</Link>
            <Button text="Entrar" variant="transparent" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
