// src/components/EditItemPopup.js
import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import Button from './Button/Button';
import H2 from './H2/H2';
import closeBtnImage from '../assets/btn-close.png';
import InputMask from 'react-input-mask';

const EditItemPopup = ({ item, onClose }) => {
  const navigate = useNavigate();
  const [itemData, setItemData] = useState({
    type: item.type || '',
    title: item.title || '',
    category: item.category || '',
    contactEmail: item.contactEmail || '',
    zipCode: item.zipCode || '',
    description: item.description || '',
  });
  const [titleCharCount, setTitleCharCount] = useState(item.title.length || 0);

  useEffect(() => {
    // Prevent body from scrolling when popup is open
    document.body.style.overflow = 'hidden';
    return () => {
      // Restore body scroll when popup is closed
      document.body.style.overflow = 'auto';
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemData({ ...itemData, [name]: value });
    if (name === 'title') {
      setTitleCharCount(value.length);
    }
  };

  const handleRadioChange = (e) => {
    setItemData({ ...itemData, type: e.target.value });
  };

  const isFormValid = () => {
    return itemData.type && itemData.title && itemData.category && itemData.contactEmail && itemData.zipCode && itemData.description;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isFormValid()) {
      alert('Por favor, preencha todos os campos.');
      return;
    }
    try {
      const itemRef = doc(db, 'mural', item.id);  // Assuming 'item.id' is the ID of the document to be updated
      await updateDoc(itemRef, {
        ...itemData,
        updatedAt: serverTimestamp(),
      });
      alert('Item atualizado com sucesso!');
      onClose();
      navigate('/mural-da-rede');
    } catch (error) {
      console.error('Erro ao atualizar item:', error);
      alert('Erro ao atualizar item.');
    }
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-[9998] bg-yellow-300 flex justify-center items-center overflow-auto">
      <div className="relative bg-white rounded-2xl p-10 flex flex-col w-11/12 md:w-2/5 mx-auto max-h-[90vh] overflow-y-auto overflow-x-hidden">
        <button
          className="absolute top-0 right-0 mt-4 mr-4 w-8 h-8 bg-transparent border-none cursor-pointer"
          onClick={onClose}
        >
          <img src={closeBtnImage} alt="Close" />
        </button>

        <H2 text="Editar item" textColor="purple" />
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <span className="font-normal text-base text-gray-600">O que deseja publicar?</span>
            <div className="flex flex-col md:flex-row gap-x-4 mt-6">
              <label className="font-normal text-base text-gray-600 flex items-center mb-2 md:mb-0">
                <input
                  type="radio"
                  name="type"
                  value="demand"
                  onChange={handleRadioChange}
                  checked={itemData.type === 'demand'}
                  className="mr-2"
                />
                Uma demanda
              </label>
              <label className="font-normal text-base text-gray-600 flex items-center">
                <input
                  type="radio"
                  name="type"
                  value="offer"
                  onChange={handleRadioChange}
                  checked={itemData.type === 'offer'}
                  className="mr-2"
                />
                Uma oferta
              </label>
            </div>
          </div>

          <div className="flex flex-wrap -mx-2 mb-4">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <label className="font-normal text-base text-gray-600 block">
                Título
                <input
                  type="text"
                  name="title"
                  value={itemData.title}
                  onChange={handleInputChange}
                  maxLength="40"
                  required
                  className="w-full border h-12 border-gray-300 rounded-xl p-2 mt-1"
                />
              </label>
              <p className="text-left text-gray-600 text-sm">Caracteres: {titleCharCount}/40</p>
            </div>
            <div className="w-full md:w-1/2 px-2">
              <label className="font-normal text-base text-gray-600 block">
                Categoria
                <select
                  name="category"
                  value={itemData.category}
                  onChange={handleInputChange}
                  required
                  className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                >
                  <option value="">Selecione</option>
                  <option value="moradia">Moradia</option>
                  <option value="alimentacao">Alimentação</option>
                  <option value="trocas">Trocas</option>
                  <option value="transporte">Transporte</option>
                  <option value="documentacao">Documentação</option>
                  <option value="emprego">Emprego/Renda</option>
                  <option value="comunicacao">Comunicação</option>
                  <option value="treinamento">Treinamento</option>
                  <option value="saude">Saúde</option>
                  <option value="outros">Outros</option>
                </select>
              </label>
            </div>
          </div>

          <div className="flex flex-wrap -mx-2 mb-4">
            <div className="w-full lg:w-1/2 px-2 mb-4 lg:mb-0">
              <label className="font-normal text-base text-gray-600 block">
                Insira um e-mail de contato
                <input
                  type="email"
                  name="contactEmail"
                  value={itemData.contactEmail}
                  onChange={handleInputChange}
                  required
                  className="w-full border border-gray-300 rounded-xl h-12 p-2 mt-1"
                />
              </label>
            </div>
            <div className="w-full lg:w-1/2 px-2">
              <label className="font-normal text-base text-gray-600 block">
                CEP da oferta/demanda
                <InputMask
                  mask="99999-999"
                  value={itemData.zipCode}
                  onChange={handleInputChange}
                >
                  {(inputProps) => (
                    <input
                      {...inputProps}
                      type="text"
                      name="zipCode"
                      required
                      className="w-full border border-gray-300 rounded-xl h-12 p-2 mt-1"
                    />
                  )}
                </InputMask>
              </label>
            </div>
          </div>

          <div className="mb-4">
            <label className="font-normal text-base text-gray-600 block">
              Descreva brevemente a oferta/demanda
              <textarea
                name="description"
                value={itemData.description}
                onChange={handleInputChange}
                maxLength="450"
                required
                className="w-full h-36 p-2 border border-gray-300 rounded-md"
              />
            </label>
            <p className="text-left text-sm text-gray-600">Caracteres: {itemData.description.length}/450</p>
          </div>

          <div className="flex justify-end mt-4">
            <Button text="Atualizar" variant="purple" type="submit" />
          </div>
        </form>
      </div>
    </div>,
    document.body
  );
};

export default EditItemPopup;