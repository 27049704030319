// src/compoments/Hero.js
import React, { useState, useEffect, useCallback } from 'react';
import HeroSlide from './HeroSlide';

const Hero = ({ highlightedPosts }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const nextSlide = useCallback(() => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentSlide((prev) => (prev + 1) % highlightedPosts.length);
      setIsFading(false);
    }, 500); 
  }, [highlightedPosts.length]);

  useEffect(() => {
    const timer = setTimeout(nextSlide, 6000);
    return () => clearTimeout(timer);
  }, [currentSlide, nextSlide]);

  return (
    <div className="bg-[#BB5313] bg-cover bg-center flex items-center justify-center relative w-full h-[700px] md:h-[553px]"
         style={{ backgroundImage: "url('/img/bg-blog.svg')" }}>
      <div className="max-w-screen-xl mx-auto px-4 relative w-full">
        {highlightedPosts.map((post, index) => (
          <HeroSlide
            key={index}
            post={post}
            isActive={index === currentSlide}
            isFading={isFading}
          />
        ))}
      </div>
      <div className="absolute bottom-7 left-1/2 transform -translate-x-1/2">
        {highlightedPosts.map((_, index) => (
          <span 
            key={index} 
            className={`inline-block h-2.5 w-2.5 m-1 rounded-full cursor-pointer ${index === currentSlide ? 'bg-blue-600' : 'bg-white'}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Hero;
