// src/components/DeleteAccountPopup.js
import React from 'react';
import Button from './Button/Button';
import H2 from './H2/H2';

const DeleteAccountPopup = ({ onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-yellow-400 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white rounded-2xl p-10 shadow-lg flex flex-col justify-between w-2/5">
        <H2 text="Excluir conta" textColor="purple" />
        <p className="font-source-sans-pro text-base text-black leading-5 mb-8">
          Você está prestes a excluir permanentemente sua conta. Esta ação é irreversível e resultará na perda de todos os seus dados, conteúdos e configurações associados a esta conta. Caso tenha certeza de que deseja excluir sua conta, por favor, confirme sua decisão abaixo.
        </p>
        <div className="flex justify-between mt-4">
          <Button text="Cancelar" variant="solid" onClick={onClose} />
          <Button text="Confirmar exclusão" variant="transparent" onClick={onConfirm} />
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountPopup;
