// src/components/BlogCard.js
import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';

const BlogCard = ({ post, categories }) => {
  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleDateString('pt-BR', options)
      .replace(/\./g, '')
      .replace('de ', '')
      .replace('de ', '');
  };

  return (
    <Link to={`/blog/${post.uid}`} className="block overflow-hidden rounded-xl bg-white mb-6 h-100">
      <div className="h-48 bg-cover bg-center" style={{ backgroundImage: `url(${post.data.add_media.url})` }}></div>
      <div className="p-8 bg-gray-100 h-52 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center mb-3">
            <span className="bg-pink-600 text-white text-xs px-2 py-1 rounded uppercase tracking-wider">{categories[post.data.category.id]}</span>
            <span className="text-xs text-gray-600 uppercase">{formatDate(post.first_publication_date)}</span>
          </div>
          <h2 className="font-bold text-2xl mb-3">{RichText.asText(post.data.title)}</h2>
        </div>
        <span className="text-blue-600 text-sm hover:text-blue-700">Leia mais</span>
      </div>
    </Link>
  );
};

export default BlogCard;
