// src/components/ResultsCount/ResultsCount.js
import React from 'react';

const ResultsCount = ({ count }) => {
  return (
    <div className="results-count">
      Mostrando {count} resultados
    </div>
  );
};

export default ResultsCount;
