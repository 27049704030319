// src/components/MessagePopup/MessagePopup.js
import ReactDOM from 'react-dom';
import React from 'react';
import H2 from '../H2/H2';
import closeBtnImage from '../../assets/btn-close.png';

const MessagePopup = ({ title, message, onClose }) => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-[9999] bg-yellow-300 flex justify-center items-center">
      <div className="bg-white rounded-2xl p-10 flex flex-col relative w-1/3 min-w-[300px]">
        <button className="absolute -top-3.5 -right-3.5 w-8 h-8 bg-transparent border-none cursor-pointer p-0" onClick={onClose}>
          <img src={closeBtnImage} alt="Close" />
        </button>
        
        <H2 text={title} textColor="purple" />
        <p className="mb-4 text-lg text-gray-700">{message}</p>
      </div>
    </div>,
    document.body
  );
};

export default MessagePopup;
