// src/components/EntrepreneurManagement.js
import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import './EntrepreneurManagement.css';

const EntrepreneurManagement = () => {
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [editEntrepreneurId, setEditEntrepreneurId] = useState(null);
  const [editFormData, setEditFormData] = useState({});

  useEffect(() => {
    const fetchEntrepreneurs = async () => {
      const entrepreneursRef = collection(db, "entrepreneurs");
      const entrepreneursData = await getDocs(entrepreneursRef);
      setEntrepreneurs(entrepreneursData.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchEntrepreneurs();
  }, []);

  const handleEditClick = (entrepreneur) => {
    setEditEntrepreneurId(entrepreneur.id);
    setEditFormData(entrepreneur);
  };

  const handleUpdate = async () => {
    const entrepreneurRef = doc(db, "entrepreneurs", editEntrepreneurId);
    await updateDoc(entrepreneurRef, editFormData);
    setEntrepreneurs(entrepreneurs.map(ent => (ent.id === editEntrepreneurId ? { ...ent, ...editFormData } : ent)));
    setEditEntrepreneurId(null);
  };

  const handleCancelEdit = () => {
    setEditEntrepreneurId(null);
  };

  const handleDelete = async (entrepreneurId) => {
    if (window.confirm("Tem certeza de que deseja excluir este perfil? Esta ação é irreversível.")) {
      const entrepreneurRef = doc(db, "entrepreneurs", entrepreneurId);
      await deleteDoc(entrepreneurRef);
      setEntrepreneurs(entrepreneurs.filter(ent => ent.id !== entrepreneurId));
    }
  };

  const handleFormChange = (event) => {
    setEditFormData({ ...editFormData, [event.target.name]: event.target.value });
  };

  return (
    <div className="entrepreneur-management">
      <h2>Empreendedores Management</h2>
      {editEntrepreneurId ? (
        <div className="edit-form">
          <label htmlFor="companyName">Nome da Empresa</label>
          <input
            type="text"
            name="companyName"
            id="companyName"
            placeholder="Nome da Empresa"
            value={editFormData.companyName || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="businessAdditionalInfo">Informações Adicionais da Empresa</label>
          <input
            type="text"
            name="businessAdditionalInfo"
            id="businessAdditionalInfo"
            placeholder="Informações Adicionais"
            value={editFormData.businessAdditionalInfo || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="businessAddress">Endereço da Empresa</label>
          <input
            type="text"
            name="businessAddress"
            id="businessAddress"
            placeholder="Endereço"
            value={editFormData.businessAddress || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="businessCity">Cidade</label>
          <input
            type="text"
            name="businessCity"
            id="businessCity"
            placeholder="Cidade"
            value={editFormData.businessCity || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="businessEmail">Email da Empresa</label>
          <input
            type="email"
            name="businessEmail"
            id="businessEmail"
            placeholder="Email"
            value={editFormData.businessEmail || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="businessHours">Horário de Funcionamento</label>
          <input
            type="text"
            name="businessHours"
            id="businessHours"
            placeholder="Horário de Funcionamento"
            value={editFormData.businessHours || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="businessNeighborhood">Bairro</label>
          <input
            type="text"
            name="businessNeighborhood"
            id="businessNeighborhood"
            placeholder="Bairro"
            value={editFormData.businessNeighborhood || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="businessPhone">Telefone da Empresa</label>
          <input
            type="text"
            name="businessPhone"
            id="businessPhone"
            placeholder="Telefone"
            value={editFormData.businessPhone || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="businessState">Estado</label>
          <input
            type="text"
            name="businessState"
            id="businessState"
            placeholder="Estado"
            value={editFormData.businessState || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="businessZipCode">CEP</label>
          <input
            type="text"
            name="businessZipCode"
            id="businessZipCode"
            placeholder="CEP"
            value={editFormData.businessZipCode || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="category">Categoria</label>
          <input
            type="text"
            name="category"
            id="category"
            placeholder="Categoria"
            value={editFormData.category || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="companyName">Nome da Empresa</label>
          <input
            type="text"
            name="companyName"
            id="companyName"
            placeholder="Nome da Empresa"
            value={editFormData.companyName || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="facebook">Facebook</label>
          <input
            type="text"
            name="facebook"
            id="facebook"
            placeholder="Facebook"
            value={editFormData.facebook || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="instagram">Instagram</label>
          <input
            type="text"
            name="instagram"
            id="instagram"
            placeholder="Instagram"
            value={editFormData.instagram || ''}
            onChange={handleFormChange}
          />

          <label htmlFor="profileHighlight">Destaque do Perfil</label>
          <input
            type="checkbox"
            name="profileHighlight"
            id="profileHighlight"
            checked={editFormData.profileHighlight === '1'}
            onChange={(e) => handleFormChange({
              target: {
                name: e.target.name,
                value: e.target.checked ? '1' : '0'
              }
            })}
          />

          <label htmlFor="profileVisibility">Visibilidade do Perfil</label>
          <select
            name="profileVisibility"
            id="profileVisibility"
            value={editFormData.profileVisibility}
            onChange={handleFormChange}
          >
            <option value="1">Visível</option>
            <option value="0">Oculto</option>
          </select>

          <label htmlFor="website">Website</label>
          <input
            type="text"
            name="website"
            id="website"
            placeholder="Website"
            value={editFormData.website || ''}
            onChange={handleFormChange}
          />

          <button onClick={handleUpdate}>Salvar</button>
          <button className="voltar" onClick={handleCancelEdit}>Voltar</button>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Empresa</th>
              <th>Email</th>
              <th>Categoria</th>
              <th>Telefone</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {entrepreneurs.map(ent => (
              <tr key={ent.id}>
                <td>{ent.companyName}</td>
                <td>{ent.businessEmail}</td>
                <td>{ent.category}</td>
                <td>{ent.businessPhone}</td>
                <td>
                  <button onClick={() => handleEditClick(ent)}>Editar</button>
                  <button className="excluir" onClick={() => handleDelete(ent.id)}>Excluir</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default EntrepreneurManagement;
