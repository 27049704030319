export const loadGoogleMapsScript = () => {
    return new Promise((resolve, reject) => {
        const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
        if (!apiKey) {
            console.error('Google Maps API key is not set in .env file.');
            reject('API key not found.');
            return;
        }

        const scriptId = 'google-maps-script';
        if (document.getElementById(scriptId)) {
            if (window.google && window.google.maps) {
                resolve(window.google.maps);
            } else {
                reject('Google Maps script loaded but google.maps is undefined.');
            }
            return;
        }

        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (window.google && window.google.maps) {
                resolve(window.google.maps);
            } else {
                reject('Google Maps script loaded but google.maps is undefined.');
            }
        };
        script.onerror = () => reject('Google Maps script failed to load');
        document.body.appendChild(script);
    });
};
