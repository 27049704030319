// src/components/AuthorDetails.js
import React from 'react';
import distanceIcon from '../assets/ico-km-distancia.svg';
import phoneIcon from '../assets/ico-telefone-perfil.svg';
import H3 from './H3/H3';
import Avatar from './Avatar/Avatar';
import verifyIcon from '../assets/verify-icon.svg';

const AuthorDetails = ({ authorInfo, title }) => {
  if (!authorInfo) {
    return <div>Carregando...</div>;
  }

  const { firstName, lastName, avatar, about, mobile, address, verified } = authorInfo;

  return (
    <div className="flex flex-col md:p-4 pt-3 border border-t-1 border-l-0 border-r-0 border-b-0 md:border border-gray-300 md:rounded-xl bg-white max-w-md mx-auto">
      <H3 text={title} textColor="purple" />
      <div className="flex items-start -mt-4">
        <div className="shrink-0">
          <Avatar src={avatar} alt={`Avatar of ${firstName}`} name={firstName} large={false} />
        </div>
        <div className="flex flex-col ml-2">
          <div className="flex items-center"> {/* Add a div to contain both icon and name */}
            {verified && <img src={verifyIcon} alt="Verified" className="w-4 h-4 mr-1" />}
            <p className="text-sm font-bold text-gray-800">{firstName} {lastName}</p>
          </div>
          {address && (
            <div className="flex items-center mt-2">
              <img src={distanceIcon} alt="Distance" className="w-4 h-4 mr-1" />
              <p className="text-sm text-gray-500">{address.city} - {address.state}</p>
            </div>
          )}
        </div>
      </div>
      <p className="text-sm text-gray-500 mt-4 mb-0">{about}</p>
      {title !== "Perfil do empreendedor" && mobile && (
        <div className="flex items-center mt-4">
          <img src={phoneIcon} alt="Phone" className="w-5 h-5 mr-2" />
          <a href={`tel:${mobile}`} className="text-sm text-blue-700 font-bold hover:underline">{mobile}</a>
        </div>
      )}
    </div>
  );
};

export default AuthorDetails;
