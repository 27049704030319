// src/components/PrivacyPolicySkeleton.js
import React from 'react';
import './PrivacyPolicySkeleton.css';

const PrivacyPolicySkeleton = () => {
  return (
    <div className="privacy-policy-skeleton">
      <div className="skeleton-header"></div>
      <div className="skeleton-content">
        <div className="skeleton-paragraph"></div>
        <div className="skeleton-paragraph"></div>
        <div className="skeleton-paragraph"></div>
      </div>
    </div>
  );
};

export default PrivacyPolicySkeleton;
