// src/components/CategoryFilter.js
import React from 'react';
import './CategoryFilter.css'; // Ensure this CSS file exists and is styled properly
import iconMoradia from '../assets/ico-azul-moradia.svg';
import iconAlimentacao from '../assets/ico-azul-alimentacao.svg';
import iconTrocas from '../assets/ico-azul-trocas.svg';
import iconTransporte from '../assets/ico-azul-transporte.svg';
import iconDocumentacao from '../assets/ico-azul-documentacao.svg';
import iconEmprego from '../assets/ico-azul-emprego.svg';
import iconComunicacao from '../assets/ico-azul-comunicacao.svg';
import iconTreinamento from '../assets/ico-azul-treinamento.svg';
import iconSaude from '../assets/ico-azul-saude.svg';
import iconOutros from '../assets/ico-azul-outros.svg';

// Define categories with their icons
const categories = [
  { key: 'emprego', icon: iconEmprego, name: 'Emprego' },
  { key: 'saude', icon: iconSaude, name: 'Saúde' },
  { key: 'alimentacao', icon: iconAlimentacao, name: 'Alimentação' },
  { key: 'transporte', icon: iconTransporte, name: 'Transporte' },
  { key: 'moradia', icon: iconMoradia, name: 'Moradia' },
  { key: 'documentacao', icon: iconDocumentacao, name: 'Documentação' },
  { key: 'trocas', icon: iconTrocas, name: 'Trocas' },
  { key: 'comunicacao', icon: iconComunicacao, name: 'Comunicação' },
  { key: 'treinamento', icon: iconTreinamento, name: 'Treinamento' },
  { key: 'outros', icon: iconOutros, name: 'Outros' },
];

const CategoryFilter = ({ onCategorySelect }) => {
  return (
    <>
      {/* Desktop View */}
      <div className="hidden md:flex justify-center flex-wrap gap-[43px] my-12 px-0">
        {categories.map(({ key, icon, name }) => (
          <div 
            key={key} 
            onClick={() => onCategorySelect(key)}
            className="flex flex-col items-center cursor-pointer text-center"
          >
            <img 
              src={icon} 
              alt={name} 
              className="w-18 h-18 rounded-full mb-2"
            />
            <span className="font-semibold text-blue-800 capitalize">{name}</span>
          </div>
        ))}
      </div>

      {/* Mobile View */}
      <div className="md:hidden flex overflow-x-scroll py-4">
        <div className="flex flex-nowrap gap-8 px-5">
          {categories.map(({ key, icon, name }) => (
            <div 
              key={key} 
              onClick={() => onCategorySelect(key)}
              className="flex flex-col items-center cursor-pointer text-center"
            >
              <img 
                src={icon} 
                alt={name} 
                className="w-16 h-16 rounded-full mb-2"
                style={{ minWidth: '64px', minHeight: '64px' }}
              />
              <span className="font-semibold text-blue-800 capitalize">{name}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CategoryFilter;