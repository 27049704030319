// src/hooks/useAuth.js
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user); // Update isLoggedIn based on user presence
      setIsLoading(false); // Set loading to false after auth status is determined
    });

    return () => unsubscribe();
  }, [auth]);

  return { isLoggedIn, isLoading }; // Return both states
};

export default useAuth;
