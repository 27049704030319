// src/components/PersonalDataForm.js
import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import InputMask from 'react-input-mask';
import Button from './Button/Button'; 
import Avatar from './Avatar/Avatar';
import H2 from './H2/H2';
import MessagePopup from './MessagePopup/MessagePopup';
import './PersonalDataForm.css';

// Initialize all fields to ensure they are controlled
const initialUserData = {
  firstName: '',
  lastName: '',
  institutionName: '',
  birthDate: '',
  gender: '',
  email: '',
  mobile: '',
  phone: '',
  password: '',
  about: '',
  avatar: '',
};

const PersonalDataForm = ({ userId, setShowPopup }) => {
  const [userData, setUserData] = useState(initialUserData);
  const [charCount, setCharCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({ title: '', message: '' });

  useEffect(() => {
    const fetchUserData = async () => {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setUserData({ ...initialUserData, ...userSnap.data() });
      } else {
        setMessageContent({ title: 'Error', message: 'No such document!' });
        setShowMessage(true);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleNameChange = (e) => {
    // Only allow letters
    const regex = /^[A-Za-z ]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setUserData({ ...userData, [e.target.name]: e.target.value });
    }
  };
  
  const handleEmailChange = (e) => {
    const email = e.target.value;
    // Simple email regex validation
    const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (email === '' || regex.test(email)) {
      setUserData({ ...userData, [e.target.name]: email });
    }
  };

  const handlePhoneChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleAboutChange = (event) => {
    setUserData({ ...userData, about: event.target.value });
    setCharCount(event.target.value.length);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, userData);
      setMessageContent({ title: 'Minha conta', message: 'Dados atualizados com sucesso!' });
      setShowMessage(true);
    } catch (error) {
      console.error('Erro ao atualizar os dados:', error);
      setMessageContent({ title: 'Minha conta', message: 'Erro ao atualizar os dados.' });
      setShowMessage(true);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const storageRef = ref(storage, `users/${userId}/${file.name}`);
    try {
      await uploadBytes(storageRef, file);
      const photoURL = await getDownloadURL(storageRef);

      setUserData({ ...userData, avatar: photoURL });
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { avatar: photoURL });

      setMessageContent({ title: 'Minha conta', message: 'Foto atualizada com sucesso!' });
      setShowMessage(true);

    } catch (error) {
      console.error('Erro ao fazer upload da foto:', error);
      setMessageContent({ title: 'Minha conta', message: 'Erro ao fazer upload da foto.' });
      setShowMessage(true);
    }
  };

  const displayName = userData.firstName || userData.institutionName || '';

  return (
    <div className="max-w-screen-xl mx-auto px-5 mt-12 md:px-20">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 p-5">
        <div className="col-span-1 md:col-span-3 text-center flex flex-col items-center">
          <Avatar src={userData.avatar} alt="Avatar" name={displayName} large className="w-40 h-40 rounded-full bg-pink-600 flex justify-center items-center text-8xl font-bold mb-2.5" />
          <label htmlFor="file-upload" className="cursor-pointer text-blue-600 mt-4">Editar foto</label>
          <p>Envie sua foto de perfil com dimensões quadradas, idealmente 200x200 pixels ou mais.</p>
          <input id="file-upload" type="file" accept="image/*" onChange={handleFileUpload} className="hidden" />
        </div>

        <div className="col-span-1 md:col-span-9">
          <H2 text="Dados Pessoais" textColor="purple" />
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
              <label className="block">
                Primeiro nome
                <input
                  type="text"
                  name="firstName"
                  value={userData.firstName}
                  onChange={handleNameChange}
                  className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                />
              </label>
              <label className="block">
                Sobrenome
                <input
                  type="text"
                  name="lastName"
                  value={userData.lastName}
                  onChange={handleNameChange}
                  className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                />
              </label>
              <label className="block">
                Data de Nascimento
                <input type="date" name="birthDate" value={userData.birthDate} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
              </label>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
              <label className="block">
                Gênero
                <div className="select-container">
                  <select className="w-full h-12 border border-gray-300 rounded-lg pl-2.5 pr-10 mt-1 appearance-none" name="gender" value={userData.gender} onChange={handleInputChange}>
                    <option value="">Selecione</option>
                    <option value="masculino">Masculino</option>
                    <option value="feminino">Feminino</option>
                    <option value="nao-binario">Não-binário</option>
                    <option value="genero-fluido">Gênero-fluido</option>
                    <option value="transgenero">Transgênero</option>
                    <option value="intersexo">Intersexo</option>
                    <option value="prefiro-nao-informar">Prefiro não informar</option>
                    <option value="outro">Outro</option>
                  </select>
                </div>
              </label>
              <label className="block">
                Seu e-mail
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleEmailChange}
                  className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                />
              </label>
              <label className="block">
                Celular
                <InputMask
                  mask="+55 (99) 99999-9999"
                  value={userData.mobile}
                  onChange={handlePhoneChange}
                >
                  {(inputProps) => (
                    <input
                      {...inputProps}
                      type="text"
                      name="mobile"
                      className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                    />
                  )}
                </InputMask>
              </label>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
              <label className="block">
                Telefone fixo
                <InputMask
                  mask="+55 (99) 9999-9999"
                  value={userData.phone}
                  onChange={handlePhoneChange}
                >
                  {(inputProps) => (
                    <input
                      {...inputProps}
                      type="text"
                      name="phone"
                      className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
                    />
                  )}
                </InputMask>
              </label>
              <label className="block">
                Sua senha
                <input type="password" name="password" value={userData.password} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
              </label>
            </div>

            <div className="w-full mb-5">
              <label className="block">
                Sobre você <br />
                <textarea name="about" value={userData.about} onChange={handleAboutChange} maxLength="250" className="w-full h-36 p-2 border border-gray-300 rounded-md"></textarea>
              </label>
              <div className="flex justify-start">
                <p className="text-right text-gray-600">Caracteres {charCount}/250</p>
              </div>
            </div>

            <div className="flex justify-end">
              <Button text="Atualizar meus dados" variant="transparent" type="submit" />
            </div>
          </form>
        </div>
      </div>
      {showMessage && (
        <MessagePopup
          title={messageContent.title}
          message={messageContent.message}
          onClose={() => setShowMessage(false)}
        />
      )}
    </div>
  );
};

export default PersonalDataForm;

