// src/pages/HowItWorks.js
import React from 'react';
import useAuth from '../hooks/useAuth';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

const HowItWorks = () => {
  const { isLoggedIn, isLoading } = useAuth(); // Destructure to get both values

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while determining the auth status
  }
  
  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div>
        <h1>Como funciona</h1>
        {/* Additional content for the Como funciona page goes here */}
      </div>
      <Footer />
    </>
  );
}

export default HowItWorks;
