// src/components/H3/H3.js
import React from 'react';

const H3 = ({ text, textColor = 'neutral' }) => {
  // Define Tailwind color class
  const textColorClass = `text-${textColor}-700`; // Adjust the shade as needed

  return (
    <h3 className={`text-2xl font-bold mt-3 mb-10 ${textColorClass}`}>
      {text}
    </h3>
  );
};

export default H3;
