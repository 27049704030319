// src/components/BlogCardSkeleton.js
import React from 'react';
import './BlogCardSkeleton.css';

const BlogCardSkeleton = () => {
  return (
    <div className="blog-card-skeleton">
      <div className="skeleton-media"></div>
      <div className="skeleton-content">
        <div className="skeleton-line wide"></div>
        <div className="skeleton-line"></div>
        <div className="skeleton-line"></div>
        <div className="skeleton-line small"></div>
      </div>
    </div>
  );
};

export default BlogCardSkeleton;
