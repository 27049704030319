// src/pages/CreateAccount.js
import React from 'react';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import AccountCreationForm from '../components/AccountCreationForm';

const CreateAccount = () => {
  const isLoggedIn = false;

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="account-creation-container">
        <AccountCreationForm />
      </div>
      <Footer />
    </>
  );
};

export default CreateAccount;
