// src/pages/ForgotPassword.js
import React from 'react';
import Menu from '../components/Menu';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import Footer from '../components/Footer';

const ForgotPassword = () => {
  const isLoggedIn = false;

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="forgot-password-page-container">
        <ForgotPasswordForm />
      </div>
      <Footer />
    </>
  );
}

export default ForgotPassword;
