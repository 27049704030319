// src/pages/PrivacyPolicy.js
import React, { useEffect, useState } from 'react';
import { client } from '../prismicConfiguration';
import { RichText } from 'prismic-reactjs';
import { predicate } from '@prismicio/client';
import useAuth from '../hooks/useAuth';
import Menu from '../components/Menu';
import PrivacyPolicySkeleton from '../components/PrivacyPolicySkeleton';
import H2 from '../components/H2/H2';
import Footer from '../components/Footer';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  const { isLoggedIn, isLoading } = useAuth();
  const [privacyPolicyData, setPrivacyPolicyData] = useState(null);

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      const response = await client.get({
        predicates: predicate.at('document.type', 'privacy_policy'),
        pageSize: 1,
      });
      if (response && response.results.length > 0) {
        setPrivacyPolicyData(response.results[0]);
      }
    };

    fetchPrivacyPolicy();
  }, []);

  if (isLoading) {
    return <PrivacyPolicySkeleton />;
  }

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="privacy-policy-header">
        <div className="privacy-policy-header-container">
          {privacyPolicyData && (
            <H2 text={RichText.asText(privacyPolicyData.data.title)} textColor="purple" />
          )}
        </div>
      </div>
      <div className="privacy-policy-content">
        {privacyPolicyData && (
          <RichText render={privacyPolicyData.data.content} />
        )}
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
