// src/pages/About.js
import React from 'react';
import useAuth from '../hooks/useAuth';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import SobreRedeRefugia from '../components/SobreRedeRefugia';
import NossosValores from '../components/NossosValores';
import NossoImpacto from '../components/NossoImpacto';

const About = () => {
  const { isLoggedIn, isLoading } = useAuth(); // Destructure to get both values

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while determining the auth status
  }

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} /> 
      <SobreRedeRefugia />
      <NossosValores />
      <NossoImpacto />
      <Footer />
    </>
  );
};

export default About;
