// src/components/WeeklyHighlights.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc, limit } from 'firebase/firestore';
import H1 from './H1/H1'; 
import H2 from './H2/H2';
import EntrepreneurSkeleton from './EntrepreneurSkeleton';
import EntrepreneurCard from './EntrepreneurCard/EntrepreneurCard';
import EntrepreneurDetailsPopup from './EntrepreneurDetailsPopup';
import Button from './Button/Button';

const WeeklyHighlights = ({ showJoinUsButton }) => {
  const [highlightedEntrepreneurs, setHighlightedEntrepreneurs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 

  const [selectedEntrepreneur, setSelectedEntrepreneur] = useState(null);
  const [authorInfo, setAuthorInfo] = useState(null);

  // Utility to remove URLs from strings, safely handling undefined or null inputs
  // More robust URL sanitization function
  const sanitizeText = (text) => {
    if (!text) return '';
    return text.replace(/(https?:\/\/[^\s]+)/g, '[link removido]');
  };

  const onShowDetails = (entrepreneur) => {
    setSelectedEntrepreneur(entrepreneur);
  };

  useEffect(() => {
    const fetchAuthorInfo = async () => {
      if (selectedEntrepreneur && selectedEntrepreneur.userId) {
        const userRef = doc(db, "users", selectedEntrepreneur.userId);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists()) {
          let userInfo = userSnap.data();
          // Sanitize text fields that might contain URLs
          userInfo.aboutBusiness = sanitizeText(userInfo.aboutBusiness);
          setAuthorInfo(userInfo);
        } else {
          setAuthorInfo(null);
        }
      }
    };

    fetchAuthorInfo();
  }, [selectedEntrepreneur]);

  useEffect(() => {
    const fetchHighlightedEntrepreneurs = async () => {
      setLoading(true);
      const q = query(
        collection(db, "entrepreneurs"),
        where("profileHighlight", "==", "1"),
        limit(3)
      );

      const querySnapshot = await getDocs(q);
      const entrepreneurs = [];
      for (const docSnapshot of querySnapshot.docs) {
        const entrepreneurData = docSnapshot.data();
        const userRef = doc(db, 'users', entrepreneurData.userId);
        const userSnap = await getDoc(userRef);
        entrepreneurs.push({
          id: docSnapshot.id,
          ...entrepreneurData,
          description: sanitizeText(entrepreneurData.description),  // Sanitize description
          userName: userSnap.exists() ? userSnap.data().firstName : 'Unknown',
          userAvatar: userSnap.exists() ? userSnap.data().avatar : null
        });
      }

      setHighlightedEntrepreneurs(entrepreneurs);
      setLoading(false);
    };

    fetchHighlightedEntrepreneurs();
  }, []);

  // Handler for the button click
  const handleNavigate = () => {
    navigate('/vitrine-de-empreendedores');
  };

  return (
    <div className={showJoinUsButton ? "weekly-highlights-internal" : "weekly-highlights"}>
      <div className="highlights-container">
        {!showJoinUsButton && (
          <>
            <H1 text="Vitrine de Empreendedores" bgColor="purple" fontColor="white" />
            <H2 text="Destaques da semana" />
          </>
        )}
        {showJoinUsButton && (
          <>
            <div className="flex justify-center">
              <H1 text="Vitrine de Empreendedores" bgColor="purple" fontColor="white" />
            </div>

            <div className="flex justify-center mt-6 md:mt-0">
              <H2 text="Conheça empreendimentos criados por pessoas migrantes e refugiados" textColor="vitrine-refugia" fontWeight="regular" />
            </div>
          </>
        )}
        <div className="grid grid-cols-1 mt-10 mb-10 md:grid-cols-3 gap-4">
          {loading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <EntrepreneurSkeleton key={index} />
            ))
          ) : (
            highlightedEntrepreneurs.map(entrepreneur => (
              <EntrepreneurCard 
                key={entrepreneur.id} 
                entrepreneur={entrepreneur} 
                onShowDetails={onShowDetails}
              />
            ))
          )}
        </div>
        {showJoinUsButton && (
          <div className="flex justify-center mt-4">
            <Button text="Conhecer mais empreendedores" variant="solid" onClick={handleNavigate} />
          </div>
        )}
      </div>
      {selectedEntrepreneur && (
        <EntrepreneurDetailsPopup
          entrepreneur={selectedEntrepreneur}
          onClose={() => setSelectedEntrepreneur(null)}
          authorInfo={authorInfo}
        />
      )}
    </div>
  );
};

export default WeeklyHighlights;