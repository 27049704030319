// src/pages/MessageCenter.js
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useProtectedRoute from '../hooks/useProtectedRoute';
import Menu from '../components/Menu';
import H1 from '../components/H1/H1'; 
import Footer from '../components/Footer';
import './MessageCenter.css';
import ConversationList from '../components/ConversationList';
import MessageArea from '../components/MessageArea';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const MessageCenter = () => {
  const isLoggedIn = useProtectedRoute();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isMobileMessageVisible, setMobileMessageVisible] = useState(false);  // State to toggle mobile view
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      const fetchConversations = async () => {
        const q = query(collection(db, "conversations"), where("users", "array-contains", currentUser.uid));
        const querySnapshot = await getDocs(q);
        let fetchedConversations = [];
        querySnapshot.forEach((doc) => {
          fetchedConversations.push({ id: doc.id, ...doc.data() });
        });
        setConversations(fetchedConversations);

        // Check and set selected conversation after conversations are fetched
        if (location.state && location.state.selectedConversationId) {
          const selectedConv = fetchedConversations.find(conv => conv.id === location.state.selectedConversationId);
          setSelectedConversation(selectedConv);
          setMobileMessageVisible(true); // Show message area on selection
        }
      };
      fetchConversations();
    }
  }, [currentUser, location.state]);

  const handleSelectConversation = (conversation) => {
    setSelectedConversation(conversation);
    setMobileMessageVisible(true); // Show messages when a conversation is selected on mobile
  };

  const handleBackToConversations = () => {
    setMobileMessageVisible(false); // Hide messages and show conversation list on mobile
  };

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="center-container">
        <div className="row-container">
          <H1 text="Mensagens" bgColor="purple" fontColor="white" />
          <Link to="/mural-da-rede" className="back-to-mural-link">
            <span>&lt; Voltar para o mural</span>
          </Link>
        </div>
        
        <div className="message-center-container overflow-y-scroll flex flex-col md:flex-row">
          <div className={`${isMobileMessageVisible ? 'hidden' : 'block'} md:block`}>
            <ConversationList 
              conversations={conversations} 
              selectedConversation={selectedConversation} 
              onSelectConversation={handleSelectConversation} 
            />
          </div>
          {selectedConversation && (
            <div className={`${isMobileMessageVisible ? 'block' : 'hidden'} md:block flex-1`}>
              <button onClick={handleBackToConversations} className="md:hidden text-blue-refugia font-bold text-sm ml-6 mt-8">&lt; Voltar</button>
              <MessageArea conversation={selectedConversation} />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MessageCenter;