// src/components/AddressForm.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import InputMask from 'react-input-mask';
import Button from './Button/Button';
import H2 from './H2/H2';
import MessagePopup from './MessagePopup/MessagePopup';
import { loadGoogleMapsScript } from '../utils/loadGoogleMapsScript';  // Import the utility function

// Initialize all address fields
const initialAddressData = {
  country: 'Brasil',
  city: '',
  state: '',
  neighborhood: '',
  address: '',
  additionalInfo: '',
  zipCode: '',
  situation: '',
  displacementReason: ''
};

const AddressForm = ({ userId, setShowPopup }) => {
  const [addressData, setAddressData] = useState(initialAddressData);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({ title: '', message: '' });

  useEffect(() => {
    const fetchAddressData = async () => {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setAddressData({ ...initialAddressData, ...userSnap.data().address });
      }
    };

    if (userId) {
      fetchAddressData();
    }
  }, [userId]);

  const handleCepBlur = async () => {
    const cep = addressData.zipCode.replace('-', '');
    if (cep.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        if (!response.data.erro) {
          setAddressData({
            ...addressData,
            city: response.data.localidade,
            state: response.data.uf,
            neighborhood: response.data.bairro,
            address: response.data.logradouro
          });
        } else {
          console.error('CEP not found.');
        }
      } catch (error) {
        console.error('Error fetching CEP:', error);
      }
    }
  };

  const handleCepChange = async (e) => {
	  const newCep = e.target.value;
	  setAddressData({ ...addressData, zipCode: newCep });
	
	  const cleanCep = newCep.replace('-', '');
	  if (/^\d{5}-\d{3}$/.test(cleanCep)) {  // Use cleanCep here instead of newCep
	    loadGoogleMapsScript(() => {
	      const geocoder = new window.google.maps.Geocoder();
	      geocoder.geocode({ 'address': cleanCep + ', Brasil' }, function(results, status) {  // Also using cleanCep here
	        if (status === 'OK' && results[0]) {
	          const locationDetails = results[0].address_components;
	          setAddressData(prevState => ({
	            ...prevState,
	            city: locationDetails.find(c => c.types.includes("administrative_area_level_2")).long_name,
	            state: locationDetails.find(c => c.types.includes("administrative_area_level_1")).short_name,
	            neighborhood: locationDetails.find(c => c.types.includes("sublocality_level_1")).long_name,
	            address: locationDetails.find(c => c.types.includes("route")).long_name
	          }));
	        } else {
	          console.error('Geocode was not successful for the following reason: ' + status);
	        }
	      });
	    });
	  }
	};

  const handleInputChange = (e) => {
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { address: addressData });
      setMessageContent({ title: 'Minha conta', message: 'Endereço atualizado com sucesso!' });
      setShowMessage(true);
    } catch (error) {
      console.error('Erro ao atualizar o endereço:', error);
      setMessageContent({ title: 'Minha conta', message: 'Erro ao atualizar o endereço.' });
      setShowMessage(true);
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto px-5 mt-12 md:px-20">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 p-5">
        <div className="col-span-1 md:col-span-3">
          {/* Empty div for layout consistency */}
        </div>
        <div className="col-span-1 md:col-span-9">
          <H2 text="Endereço" textColor="purple" />
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
              <label className="block">
                CEP
                <InputMask
								  mask="99999-999"
								  value={addressData.zipCode}
								  onChange={handleCepChange}
								  onBlur={handleCepBlur}
								  className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1"
								  placeholder="00000-000"
								/>
              </label>
              <label className="block">
                Estado
                <input type="text" name="state" value={addressData.state} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
              </label>
              <label className="block">
                Cidade
                <input type="text" name="city" value={addressData.city} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
              </label>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-5">
              <label className="block">
                Bairro
                <input type="text" name="neighborhood" value={addressData.neighborhood} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
              </label>
              <label className="block">
                Endereço
                <input type="text" name="address" value={addressData.address} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
              </label>
              <label className="block">
                Complemento
                <input type="text" name="additionalInfo" value={addressData.additionalInfo} onChange={handleInputChange} className="w-full h-12 border border-gray-300 rounded-lg px-2.5 mt-1" />
              </label>
            </div>
            <div className="flex justify-end mt-4">
              <Button text="Atualizar endereço" variant="transparent" type="submit" />
            </div>
          </form>
        </div>
      </div>
      {showMessage && (
        <MessagePopup
          title={messageContent.title}
          message={messageContent.message}
          onClose={() => setShowMessage(false)}
        />
      )}
    </div>
  );
};

export default AddressForm;