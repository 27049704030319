// src/pages/AdminCMS.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig'; // Make sure this path is correct
import { collection, getDocs } from 'firebase/firestore';
import './AdminCMS.css';
import UsersManagement from '../components/UsersManagement'; 
import MuralManagement from '../components/MuralManagement';
import EntrepreneurManagement from '../components/EntrepreneurManagement';
import NossosResultadosManagement from '../components/NossosResultadosManagement'; 
import ParceirosDaRedeManagement from '../components/ParceirosDaRedeManagement'; 
import NewsletterManagement from '../components/NewsletterManagement'; 

const AdminCMS = () => {
  const [userCount, setUserCount] = useState(0);
  const [muralItemCount, setMuralItemCount] = useState(0);
  const [entrepreneurCount, setEntrepreneurCount] = useState(0);

  const [activeComponent, setActiveComponent] = useState('dashboard');

  const handleMenuClick = (component) => {
    setActiveComponent(component);
  };

  useEffect(() => {
    // Fetch users
    const fetchUsers = async () => {
      const userCollectionRef = collection(db, 'users');
      const userData = await getDocs(userCollectionRef);
      setUserCount(userData.size);
    };

    // Fetch mural items
    const fetchMuralItems = async () => {
      const muralCollectionRef = collection(db, 'mural');
      const muralData = await getDocs(muralCollectionRef);
      setMuralItemCount(muralData.size);
    };

    // Fetch entrepreneurs
    const fetchEntrepreneurs = async () => {
      const entrepreneurCollectionRef = collection(db, 'entrepreneurs');
      const entrepreneurData = await getDocs(entrepreneurCollectionRef);
      setEntrepreneurCount(entrepreneurData.size);
    };

    fetchUsers();
    fetchMuralItems();
    fetchEntrepreneurs();
  }, []);

  return (
    <div className="admin-cms-container">
      <aside className="sidebar">
        <nav>
          <ul>
            <li onClick={() => handleMenuClick('dashboard')}>Dashboard</li>
            <li onClick={() => handleMenuClick('users')}>Users</li>
            <li onClick={() => handleMenuClick('mural')}>Mural</li>
            <li onClick={() => handleMenuClick('entrepreneur')}>Vitrine de Empreendedores</li>
            <li onClick={() => handleMenuClick('nossosResultados')}>Nossos Resultados</li>
            <li onClick={() => handleMenuClick('parceiros')}>Parceiros da Rede</li>
            <li onClick={() => handleMenuClick('newsletter')}>Newsletter</li>
            {/* ... other menu items */}
          </ul>
        </nav>
      </aside>
      <section className="dashboard">
        {activeComponent === 'dashboard' && (
          <>
            <h1>Bem-vindo ao Admin CMS Dashboard</h1>
            <div className="dashboard-stats">
              <div className="stat">
                <h2>Usuários cadastrados</h2>
                <p>{userCount}</p>
              </div>
              <div className="stat">
                <h2>Items no Mural</h2>
                <p>{muralItemCount}</p>
              </div>
              <div className="stat">
                <h2>Empreendedores listados</h2>
                <p>{entrepreneurCount}</p>
              </div>
            </div>
          </>
        )}
        {activeComponent === 'users' && <UsersManagement />}
        {activeComponent === 'mural' && <MuralManagement />}
        {activeComponent === 'entrepreneur' && <EntrepreneurManagement />}
        {activeComponent === 'nossosResultados' && <NossosResultadosManagement />}
        {activeComponent === 'parceiros' && <ParceirosDaRedeManagement />}
        {activeComponent === 'newsletter' && <NewsletterManagement />}
      </section>
    </div>
  );
};

export default AdminCMS;
