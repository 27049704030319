// src/components/DeleteMuralItemPopup.js
import React from 'react';
import Button from './Button/Button';
import H2 from './H2/H2';

const DeleteMuralItemPopup = ({ onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-yellow-400 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white rounded-2xl p-10 shadow-lg flex flex-col justify-between w-2/6">
        <H2 text="Excluir Item" textColor="purple" />
        <p className="font-source-sans-pro text-base text-black leading-5 mb-8">
          Você está prestes a excluir este item. Esta ação é irreversível. Caso tenha certeza de que deseja excluir este item, por favor, confirme sua decisão abaixo.
        </p>
        <div className="flex justify-between mt-4">
          <Button text="Cancelar" variant="solid" onClick={onClose} />
          <Button text="Confirmar exclusão" variant="transparent" onClick={onConfirm} />
        </div>
      </div>
    </div>
  );
};

export default DeleteMuralItemPopup;
