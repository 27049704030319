// src/pages/Home.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import MainHero from '../components/MainHero';
import FacaParte from '../components/FacaParte';
import OportunidadesDemandas from '../components/OportunidadesDemandas';
import NossosResultados from '../components/NossosResultados';
import AgoraEComVoce from '../components/AgoraEComVoce';
import ParceirosDaRede from '../components/ParceirosDaRede';

const Home = () => {
  const { isLoggedIn, isLoading } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToComoFunciona) {
      setTimeout(() => {
        const section = document.querySelector('.faca-parte');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Delay the scroll operation by 100 milliseconds
    }
  }, [location.state]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <MainHero />
      <FacaParte />
      <OportunidadesDemandas />
      <NossosResultados />
      <AgoraEComVoce />
      <ParceirosDaRede />
      <Footer />
    </>
  );
}

export default Home;
