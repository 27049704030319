// src/components/H4/H4.js
import React from 'react';

const H4 = ({ text, textColor = 'neutral' }) => {
  // Define Tailwind color class
  const textColorClass = `text-${textColor}-700`; // Adjust the shade as needed

  return (
    <h4 className={`text-xl font-bold -mt-8 mb-10 ${textColorClass}`}>
      {text}
    </h4>
  );
};

export default H4;
