// src/pages/AdsWall.js
import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth';
import { collection, query, doc, deleteDoc, addDoc, getDoc, getDocs, limit, startAfter, where, orderBy } from 'firebase/firestore';
import useProtectedRoute from '../hooks/useProtectedRoute';
import Button from '../components/Button/Button';
import H1 from '../components/H1/H1'; 
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import MuralItemSkeleton from '../components/MuralItemSkeleton';
import MuralItem from "../components/MuralItem/MuralItem";
import PublishItemPopup from '../components/PublishItemPopup';
import CategoryFilter from "../components/CategoryFilter";
import SelectedFilters from '../components/SelectedFilters';
import FilterBar from '../components/FilterBar/FilterBar';
import ResultsCount from '../components/ResultsCount/ResultsCount';
import infoIcon from '../assets/ico-info.svg';
import './AdsWall.css';

const ITEMS_PER_PAGE = 16;

const AdsWall = () => {
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [items, setItems] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setSelectedType] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistance, setSelectedDistance] = useState(300);
  const [isFetching, setIsFetching] = useState(false);

  const isLoggedIn = useProtectedRoute();

  const clearFilters = () => {
    setSelectedCategory(null);
    setSelectedType('');
    setSelectedState('');
    setSelectedDistance(300);
    setLastVisible(null);
    setItems([]);
    setHasMoreItems(true);
  };

  const auth = getAuth();
  const currentUser = auth.currentUser;
  const userId = currentUser ? currentUser.uid : null;

  const [showPublishPopup, setShowPublishPopup] = useState(false);
  const openPublishPopup = () => setShowPublishPopup(true);

  const fetchUserData = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    return userSnap.exists() ? userSnap.data() : {};
  };

  const checkIfFavorited = useCallback(async (itemId) => {
    const user = auth.currentUser;
    if (!user) return false;

    const favoritesRef = collection(db, 'favorites');
    const q = query(favoritesRef, where('userId', '==', user.uid), where('muralItemId', '==', itemId));
    const snapshot = await getDocs(q);
    return !snapshot.empty;
  }, [auth.currentUser]);

  const removeItemFromState = (itemId) => {
    setItems(prevItems => prevItems.filter(item => item.id !== itemId));
  };

  const handleCategorySelect = useCallback((category) => {
    setSelectedCategory(category);
    setLastVisible(null);
    setItems([]);
    setHasMoreItems(true);
  }, []);

  const buildQuery = useCallback(() => {
    let q = query(collection(db, "mural"), orderBy("createdAt", "desc"));

    if (selectedCategory) {
      q = query(q, where("category", "==", selectedCategory));
    }

    if (selectedType) {
      q = query(q, where("type", "==", selectedType));
    }

    if (selectedState) {
      q = query(q, where("estado", "==", selectedState));
    }

    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    return query(q, limit(ITEMS_PER_PAGE));
  }, [selectedCategory, selectedType, selectedState, lastVisible]);

  const fetchMuralItems = useCallback(async () => {
    if (isFetching || !isLoggedIn || !hasMoreItems) return;
    setIsFetching(true);
    setLoading(true);

    try {
      const q = buildQuery();
      const snapshot = await getDocs(q);
      const newItems = [];
      for (const docSnap of snapshot.docs) {
        const userData = await fetchUserData(docSnap.data().userId);
        const isFavorited = await checkIfFavorited(docSnap.id);

        newItems.push({
          id: docSnap.id,
          ...docSnap.data(),
          userName: userData?.institutionName || userData?.firstName,
          userAvatar: userData?.avatar,
          userCity: userData?.address?.city,
          userState: userData?.address?.state,
          isFavorite: isFavorited
        });
      }

      setItems(prevItems => [...prevItems, ...newItems]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMoreItems(snapshot.docs.length >= ITEMS_PER_PAGE);
    } catch (error) {
      console.error("Error fetching items:", error);
      // Handle the error gracefully or fallback query
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
  }, [isFetching, isLoggedIn, hasMoreItems, checkIfFavorited, buildQuery]);

  useEffect(() => {
    fetchMuralItems();
  }, [fetchMuralItems, selectedCategory, selectedType, selectedState]);

  const toggleFavorite = async (itemId) => {
    const user = auth.currentUser;
    if (!user) return;

    const favoritesRef = collection(db, 'favorites');
    const q = query(favoritesRef, where('userId', '==', user.uid), where('muralItemId', '==', itemId));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      await deleteDoc(snapshot.docs[0].ref);
    } else {
      await addDoc(favoritesRef, { userId: user.uid, muralItemId: itemId });
    }

    const updatedItems = items.map(item => {
      if (item.id === itemId) {
        return { ...item, isFavorite: snapshot.empty };
      }
      return item;
    });

    setItems(updatedItems);
  };

  const loadMoreItems = async () => {
    if (!lastVisible || isFetching) return;
    setIsFetching(true);

    try {
      const q = buildQuery();
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const newItems = [];
        for (const docSnap of snapshot.docs) {
          if (!items.find(item => item.id === docSnap.id)) {
            const itemData = docSnap.data();
            const isFavorited = await checkIfFavorited(docSnap.id);
            newItems.push({
              id: docSnap.id,
              ...itemData,
              userName: itemData.institutionName || itemData.firstName,
              userAvatar: itemData.avatar,
              userCity: itemData.address.city,
              userState: itemData.address.state,
              isFavorite: isFavorited
            });
          }
        }

        setItems(prev => [...prev, ...newItems]);
        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      } else {
        setHasMoreItems(false);
      }
    } catch (error) {
      console.error("Error loading more items:", error);
    } finally {
      setIsFetching(false);
    }
  };

  if (!isLoggedIn) {
    return null;
  }

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setLastVisible(null);
    setItems([]);
    setHasMoreItems(true);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setLastVisible(null);
    setItems([]);
    setHasMoreItems(true);
  };

  const handleDistanceChange = (e) => {
    setSelectedDistance(e.target.value);
  };

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="ads-wall-container">
        <div className="header-container">
          <H1 text="Mural da Rede" bgColor="purple" fontColor="white" />
          <div className="header-message-container">
            <img src={infoIcon} alt="Info" className="info-icon" />
            <p className="header-message">
              A Rede Refugia só é possível com a sua publicação. Não deixe de <span className="post-link" onClick={openPublishPopup}>postar a sua oferta ou demanda.</span>
            </p>
          </div>
        </div>
        <CategoryFilter onCategorySelect={handleCategorySelect} />

        <div className="flex items-center justify-between py-4 px-0">
          <div className="flex items-center">
            <FilterBar
              selectedType={selectedType}
              handleTypeChange={handleTypeChange}
              selectedState={selectedState}
              handleStateChange={handleStateChange}
              selectedDistance={selectedDistance}
              handleDistanceChange={handleDistanceChange}
            />
          </div>
          <div className="flex items-center">
            <ResultsCount count={items.length} />
          </div>
        </div>

        <SelectedFilters selectedCategory={selectedCategory} onClearFilters={clearFilters} />
        <div className="ads-wall-grid">
          {loading ? (
            Array.from({ length: ITEMS_PER_PAGE }, (_, index) => (
              <MuralItemSkeleton key={index} />
            ))
          ) : (
            items.map(item => (
              <MuralItem 
                key={item.id} 
                item={item} 
                onToggleFavorite={toggleFavorite} 
                removeItemFromState={removeItemFromState}
              />
            ))
          )}
        </div>
        {loading && <p>Loading...</p>}
        {!loading && hasMoreItems && (
          <div className="flex justify-center mt-4">
            <Button text="Carregar mais" variant="solid" onClick={loadMoreItems} />
          </div>
        )}
      </div>
      {isLoggedIn && showPublishPopup && (
        <PublishItemPopup userId={userId} onClose={() => setShowPublishPopup(false)} />
      )}
      <Footer />
    </>
  );
};

export default AdsWall;


