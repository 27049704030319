// src/components/H2/H2.js
import React from 'react';

const H2 = ({ text, textColor = 'neutral', fontWeight = 'bold' }) => {
  const textColorClass = `text-${textColor}-700`;
  const fontWeightClass = `font-${fontWeight}`;

  // Render the text as HTML
  const createMarkup = () => ({ __html: text });

  return (
    <h2 className={`text-3xl mb-10 max-h-fit ${textColorClass} ${fontWeightClass}`} dangerouslySetInnerHTML={createMarkup()} />
  );
};

export default H2;
