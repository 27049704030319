// src/components/Button/Button.js
import React, { useState } from 'react';
import messageIconOff from '../../assets/ico-mensagem-off.svg';
import messageIconOn from '../../assets/ico-mensagem-on.svg';
import editIcon from '../../assets/ico-editar.svg';

// Button component
const Button = ({ text, variant, onClick, icon }) => {
  const [isHovered, setIsHovered] = useState(false);

  // Base styles for all buttons
  const commonStyle = 'rounded-full font-semibold px-6 md:px-8 py-3 text-base focus:outline-none flex items-center justify-center';

  // Styles for solid variant
  const solidStyle = 'bg-blue-refugia text-neutral-50 border-blue-800 hover:bg-pink-600';

  // Styles for transparent variant
  const transparentStyle = 'mx-1 mr-3 bg-transparent text-blue-800 border border-blue-800 hover:bg-blue-refugia hover:text-white hover:bg-blue-refugia';

  // Styles for icon variant
  const iconStyle = 'flex items-center space-x-2';

  // Determine the button style based on the 'variant' prop
  const buttonStyle = variant === 'solid' ? solidStyle : (variant === 'icon' ? `${transparentStyle} ${iconStyle}` : transparentStyle);

  // Logic to handle icon switching based on hover state and icon type
  let currentIcon = icon;
  if (icon === messageIconOff && isHovered) {
    currentIcon = messageIconOn;
  } else if (icon === 'edit') {
    currentIcon = editIcon;
  }

  return (
    <button
      className={`${commonStyle} ${buttonStyle}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon && <img src={currentIcon} alt="" className="hidden md:block w-4 h-4 mr-2" />}
      <span>{text}</span>
    </button>
  );
};

export default Button;
