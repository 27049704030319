// src/components/Footer.js
import React from 'react';
import logo from '../assets/logo-footer.svg';
import HrefLink from './HrefLink/HrefLink';
import H3 from './H3/H3';
import facebookIcon from '../assets/ico-facebook.svg';
import instagramIcon from '../assets/ico-instagram.svg';
import linkedinIcon from '../assets/ico-linkedin.svg';

const Footer = () => {
  return (
    <div className="w-full bg-footer-refugia">
      <footer className="max-w-7xl mx-auto pt-10 md:pt-20 pb-5 md:pb-20 pl-5 pr-10 md:pr-5 text-gray-700 text-base font-light font-sans">
        {/* Mobile View */}
        <div className="flex flex-col md:hidden">
          <div className="flex justify-between items-start w-full mb-8">
            <div className="flex flex-col justify-start items-start w-1/2">
              <img src={logo} alt="Logo" className="h-16 mb-4" />
              <p>&copy; {new Date().getFullYear()} Rede Refugia.<br />
                <HrefLink href="/politica-de-privacidade" label="Política de privacidade" className="text-pink-500 font-bold" />
              </p>
              <div className="flex mt-4">
                <a href="https://web.facebook.com/rederefugia?locale=pt_BR" target="_blank" rel="noreferrer" className="mr-4">
                  <img src={facebookIcon} alt="Facebook" className="h-8 w-8" />
                </a>
                <a href="https://www.instagram.com/rederefugia/" target="_blank" rel="noreferrer" className="mr-4">
                  <img src={instagramIcon} alt="Instagram" className="h-8 w-8" />
                </a>
                <a href="https://www.linkedin.com/in/rederefugia/" target="_blank" rel="noreferrer" className="mr-4">
                  <img src={linkedinIcon} alt="LinkedIn" className="h-8 w-8" />
                </a>
              </div>
            </div>
            <div className="flex-grow flex flex-col justify-center ml-4 w-1/2">
              <div className="flex flex-col items-start">
                <H3 text="Contato" textColor="purple" />
                <p>Av. Horácio Macedo, 2030, Rio de Janeiro,<br />RJ, CEP 21941-598<br />
                  Telefone: +55 21 4256-4321<br />
                  E-mail: <HrefLink href="mailto:rederefugia@pep.ufrj.br" label="rederefugia@pep.ufrj.br" className="text-pink-500 font-bold" />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Desktop View */}
        <div className="hidden md:flex md:flex-row justify-between items-center">
          <div className="flex flex-col justify-start items-start w-full md:w-auto mb-8 md:mb-0">
            <img src={logo} alt="Logo" className="h-16 mb-4" />
            <p>&copy; {new Date().getFullYear()} Rede Refugia.<br />
              <HrefLink href="/politica-de-privacidade" label="Política de privacidade" className="text-pink-500 font-bold" />
            </p>
          </div>

          <div className="flex-grow flex flex-col justify-center ml-4 w-full md:w-auto mb-8 md:mb-0">
            <div className="flex flex-col items-start self-end">
              <H3 text="Contato" textColor="purple" />
              <p>Av. Horácio Macedo, 2030, Rio de Janeiro,<br />RJ, CEP 21941-598<br />
                Telefone: +55 21 4256-4321<br />
                E-mail: <HrefLink href="mailto:rederefugia@pep.ufrj.br" label="rederefugia@pep.ufrj.br" className="text-pink-500 font-bold" />
              </p>
            </div>
          </div>

          <div className="hidden md:flex flex-col justify-center items-center ml-32">
            <a href="https://web.facebook.com/rederefugia?locale=pt_BR" target="_blank" rel="noreferrer" className="mb-4">
              <img src={facebookIcon} alt="Facebook" className="h-8 w-8" />
            </a>
            <a href="https://www.instagram.com/rederefugia/" target="_blank" rel="noreferrer" className="mb-4">
              <img src={instagramIcon} alt="Instagram" className="h-8 w-8" />
            </a>
            <a href="https://www.linkedin.com/in/rederefugia/" target="_blank" rel="noreferrer" className="mb-4">
              <img src={linkedinIcon} alt="LinkedIn" className="h-8 w-8" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;