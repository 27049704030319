// src/components/NossosResultadosManagement.js
import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const NossosResultadosManagement = () => {
  const [peopleImpacted, setPeopleImpacted] = useState(0);
  const [projectsSupported, setProjectsSupported] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'content', 'nossosResultados');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setPeopleImpacted(data.peopleImpacted);
        setProjectsSupported(data.projectsSupported);
      } else {
        console.error("No such document!");
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, 'content', 'nossosResultados');
    try {
      await setDoc(docRef, {
        peopleImpacted: Number(peopleImpacted),
        projectsSupported: Number(projectsSupported)
      }, { merge: true });
      alert('Dados atualizados com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar dados', error);
      alert('Erro ao atualizar dados.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-xl font-semibold text-gray-800 mb-6">Administrar Nossos Resultados</h1>
      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="peopleImpacted">
            Pessoas Impactadas
          </label>
          <input
            type="number"
            id="peopleImpacted"
            value={peopleImpacted}
            onChange={(e) => setPeopleImpacted(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="projectsSupported">
            Projetos Apoiados
          </label>
          <input
            type="number"
            id="projectsSupported"
            value={projectsSupported}
            onChange={(e) => setProjectsSupported(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Atualizar Dados
        </button>
      </form>
    </div>
  );
};

export default NossosResultadosManagement;

