// src/pages/MyPublishedItems.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth';
import { collection, query, getDocs, where, doc, getDoc } from 'firebase/firestore';
import useProtectedRoute from '../hooks/useProtectedRoute';
import Menu from '../components/Menu';
import H1 from '../components/H1/H1'; 
import Footer from '../components/Footer';
import MuralItemSkeleton from '../components/MuralItemSkeleton'; // Import the skeleton loader
import MuralItem from "../components/MuralItem/MuralItem";
import './AdsWall.css'; 

const ITEMS_PER_PAGE = 16; // Define the number of skeleton loaders

const MyPublishedItems = () => {
  const navigate = useNavigate();
  const isLoggedIn = useProtectedRoute();
  const [publishedItems, setPublishedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const fetchUserData = async (userId) => {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);
      return userSnap.exists() ? userSnap.data() : {};
    };

    const fetchPublishedItems = async () => {
      const user = auth.currentUser;
      if (user) {
        const muralItemsRef = collection(db, 'mural');
        const q = query(muralItemsRef, where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const items = [];
        for (const docSnapshot of querySnapshot.docs) {
          // Fetch user data for each published item
          const userData = await fetchUserData(docSnapshot.data().userId);
          items.push({
            id: docSnapshot.id,
            ...docSnapshot.data(),
            userName: userData.institutionName || userData.firstName,
            userAvatar: userData.avatar,
            userCity: userData.address.city,
            userState: userData.address.state,
          });
        }
        setPublishedItems(items);
        setIsLoading(false);
      }
    };

    if (isLoggedIn) {
      fetchPublishedItems();
    }
  }, [isLoggedIn, auth.currentUser]);

  // Function to remove an item from the state
  const removeItemFromState = (itemId) => {
    setPublishedItems(prevItems => prevItems.filter(item => item.id !== itemId));
  };

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="ads-wall-container">
        <div className="row-container">
          <H1 text="Meus itens criados" bgColor="purple" fontColor="white" />
          <Link to="/mural-da-rede" className="back-to-mural-link">
            <span>&lt; Voltar para o mural</span>
          </Link>
        </div>
        <div className="ads-wall-grid">
          {isLoading ? (
            <MuralItemSkeleton count={ITEMS_PER_PAGE} />
          ) : (
            publishedItems.map(item => (
              <MuralItem key={item.id} item={item} onToggleFavorite={() => {}} onNavigate={navigate} removeItemFromState={removeItemFromState} />
            ))
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MyPublishedItems;

