// src/components/MuralManagement.js
import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import './MuralManagement.css';

const MuralManagement = () => {
  const [muralItems, setMuralItems] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [editFormData, setEditFormData] = useState({});

  useEffect(() => {
    const fetchMuralItems = async () => {
      const muralCollectionRef = collection(db, "mural");
      const muralData = await getDocs(muralCollectionRef);
      setMuralItems(muralData.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchMuralItems();
  }, []);

  const handleEditClick = (item) => {
    setEditItemId(item.id);
    setEditFormData(item);
  };

  const translateType = (type) => {
    const translations = {
      'offer': 'Oferta',
      'demand': 'Demanda'
    };
    return translations[type] || type;
  };

  const handleUpdate = async () => {
    const muralDocRef = doc(db, "mural", editItemId);
    await updateDoc(muralDocRef, editFormData);
    setMuralItems(muralItems.map(item => (item.id === editItemId ? { ...item, ...editFormData } : item)));
    setEditItemId(null);
  };

  const handleCancelEdit = () => {
    setEditItemId(null);
  };

  const handleDelete = async (itemId) => {
    if (window.confirm("Tem certeza de que deseja excluir este item? Esta ação é irreversível.")) {
      const muralDocRef = doc(db, "mural", itemId);
      await deleteDoc(muralDocRef);
      setMuralItems(muralItems.filter(item => item.id !== itemId));
    }
  };

  const handleFormChange = (event) => {
    setEditFormData({ ...editFormData, [event.target.name]: event.target.value });
  };

  return (
    <div className="mural-management">
      <h2>Mural Management</h2>
      {editItemId ? (
        <div className="edit-form">
          <label htmlFor="title">Título</label>
          <input
            type="text"
            name="title"
            id="title"
            placeholder="Title"
            value={editFormData.title}
            onChange={handleFormChange}
          />

          <label htmlFor="description">Descrição</label>
          <textarea
            name="description"
            id="description"
            placeholder="Description"
            value={editFormData.description}
            onChange={handleFormChange}
          />

          <label htmlFor="category">Categoria</label>
          <input
            type="text"
            name="category"
            id="category"
            placeholder="Category"
            value={editFormData.category}
            onChange={handleFormChange}
          />

          <label htmlFor="type">Tipo</label>
          <select name="type" id="type" value={editFormData.type} onChange={handleFormChange}>
            <option value="offer">Oferta</option>
            <option value="demand">Demanda</option>
          </select>

          <button onClick={handleUpdate}>Salvar</button>
          <button className="voltar" onClick={handleCancelEdit}>Voltar</button>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Título</th>
              <th>Descrição</th>
              <th>Categoria</th>
              <th>Tipo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {muralItems.map(item => (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.description}</td>
                <td>{item.category}</td>
                <td>{translateType(item.type)}</td>
                <td>
                  <button onClick={() => handleEditClick(item)}>Editar</button>
                  <button className="excluir" onClick={() => handleDelete(item.id)}>Excluir</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MuralManagement;
