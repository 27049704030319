// src/components/EntrepreneurSkeleton.js
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const EntrepreneurSkeleton = () => {
  return (
    <div className="h-450 bg-white rounded-2xl p-5 gap-4 relative">
      <Skeleton height={24} width={`70%`} /> {/* For H3 */}
      <Skeleton height={20} width={`60%`} /> {/* For H4 */}
      <Skeleton height={35} width={`100%`} /> {/* For Location and Avatar */}
      <Skeleton height={16} count={4} width={`90%`} /> {/* For business description and links */}
      <div className="absolute bottom-7 left-2.5 right-2.5 flex justify-center">
        <Skeleton height={44} width={`100px`} /> {/* For 'Ver mais' button */}
      </div>
    </div>
  );
};

export default EntrepreneurSkeleton;
