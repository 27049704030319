// src/components/AccountCreationForm.js
import React, { useState } from "react";
import { auth, db } from "../firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Button from './Button/Button';
import H2 from './H2/H2';
import MessagePopup from './MessagePopup/MessagePopup';

const AccountCreationForm = () => {
  const [isInstitution, setIsInstitution] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({ title: '', message: '' });

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    cnpj: '',
    institutionName: '',
    email: '',
    password: '',
    termsAgreed: false
  });
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
  event.preventDefault();
  
  // Trim the inputs
  const trimmedFormData = {
    ...formData,
    firstName: formData.firstName.trim(),
    lastName: formData.lastName.trim(),
    cnpj: formData.cnpj.trim(),
    institutionName: formData.institutionName.trim(),
    email: formData.email.trim(),
  };

  // Update state with trimmed data
  setFormData(trimmedFormData);

  // Check the validity of the form
  const isFormValid = isInstitution 
    ? trimmedFormData.cnpj && trimmedFormData.institutionName && trimmedFormData.email && formData.password && formData.termsAgreed
    : trimmedFormData.firstName && trimmedFormData.lastName && trimmedFormData.email && formData.password && formData.termsAgreed;

  if (!isFormValid) {
    setMessageContent({ title: 'Criar conta', message: 'Por favor, preencha todos os campos corretamente.' });
    setShowMessage(true);
    return;
  }

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, trimmedFormData.email, formData.password);
    const user = userCredential.user;

    const userProfile = isInstitution ? {
      cnpj: trimmedFormData.cnpj,
      institutionName: trimmedFormData.institutionName,
      email: trimmedFormData.email,
      type: 'institution'
    } : {
      firstName: trimmedFormData.firstName,
      lastName: trimmedFormData.lastName,
      email: trimmedFormData.email,
      type: 'individual',
      termsAgreed: formData.termsAgreed
    };

    await setDoc(doc(db, 'users', user.uid), userProfile);
    navigate('/home-logado');
  } catch (error) {
    let errorMessage = 'Erro ao criar conta.';
    switch (error.code) {
      case 'auth/email-already-in-use':
        errorMessage = 'Erro: O e-mail fornecido já está em uso. Por favor, tente com outro e-mail.';
        break;
      case 'auth/invalid-email':
        errorMessage = 'Erro: O e-mail fornecido não é válido. Por favor, insira um e-mail correto.';
        break;
      case 'auth/weak-password':
        errorMessage = 'Erro: A senha fornecida é muito fraca. Por favor, forneça uma senha mais forte.';
        break;
      default:
        console.error("Error code not handled!", error);
    }
    setMessageContent({ title: 'Criar conta', message: errorMessage });
    setShowMessage(true);
  }
};


  return (
    <div className="flex justify-center items-center bg-blue-600 bg-cover bg-no-repeat bg-center" style={{ backgroundImage: "url(/img/bg-cadastro.svg)" }}>
      <form onSubmit={handleSubmit} className="mt-20 mb-20 lg:w-1/2 s:w-full p-10 bg-white rounded-xl mx-4">
        <H2 text="Criar conta" textColor="purple" />
        <div className="mt-4">
          <label className="block">
            <span className="text-lg text-gray-700">Você é representante de uma instituição?</span>
            <div className="mt-2">
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio" name="userType" checked={!isInstitution} onChange={() => setIsInstitution(false)} />
                <span className="ml-2 text-lg">Não</span>
              </label>
              <label className="inline-flex items-center ml-6">
                <input type="radio" className="form-radio" name="userType" checked={isInstitution} onChange={() => setIsInstitution(true)} />
                <span className="ml-2 text-lg">Sim</span>
              </label>
            </div>
            {isInstitution && <p className="mt-2 text-lg text-orange-700">Esta ação não poderá ser alterada depois do cadastro finalizado.</p>}
          </label>
        </div>
        {isInstitution ? (
          <div className="flex flex-col sm:flex-row sm:space-x-4">
            <div className="field sm:w-1/2">
              <label htmlFor="cnpj" className="block mt-4 text-lg">CNPJ</label>
              <input id="cnpj" name="cnpj" type="text"  className="w-full border border-gray-300 rounded-xl p-2" onChange={handleInputChange} />
            </div>
            <div className="field sm:w-1/2">
              <label htmlFor="institution-name" className="block mt-4 text-lg">Nome da instituição</label>
              <input id="institution-name" name="institutionName" type="text"  className="w-full border border-gray-300 rounded-xl p-2" onChange={handleInputChange} />
            </div>
          </div>
        ) : (
          <div className="flex flex-col sm:flex-row sm:space-x-4">
            <div className="field sm:w-1/2">
              <label htmlFor="first-name" className="block mt-4 text-lg">Primeiro nome</label>
              <input id="first-name" name="firstName" type="text"  className="w-full border border-gray-300 rounded-xl p-2" onChange={handleInputChange} />
            </div>
            <div className="field sm:w-1/2">
              <label htmlFor="last-name" className="block mt-4 text-lg">Sobrenome</label>
              <input id="last-name" name="lastName" type="text"  className="w-full border border-gray-300 rounded-xl p-2" onChange={handleInputChange} />
            </div>
          </div>
        )}
        <div className="flex flex-col sm:flex-row sm:space-x-4">
          <div className="field sm:w-1/2">
            <label htmlFor="email" className="block mt-4 text-lg">Seu e-mail</label>
            <input id="email" name="email" type="email"  className="w-full border border-gray-300 rounded-xl p-2" onChange={handleInputChange} />
          </div>
          <div className="field sm:w-1/2">
            <label htmlFor="password" className="block mt-4 text-lg">Sua senha</label>
            <input id="password" name="password" type="password"  className="w-full border border-gray-300 rounded-xl p-2" onChange={handleInputChange} />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center mt-10">
          <label htmlFor="terms" className="flex items-center text-lg mb-2 sm:mb-10">
            <input type="checkbox" id="terms" name="termsAgreed" className="mr-2" onChange={(e) => setFormData({ ...formData, termsAgreed: e.target.checked })} />
            Li e concordo com os <a href="/termos-de-uso" target="_blank" className="text-blue-800 text-lg hover:underline ml-1" rel="noopener noreferrer">Termos de Uso</a>
          </label>
          <Button text={isInstitution ? "Finalizar cadastro" : "Criar conta"} variant="transparent" className="w-full sm:w-auto" type="submit" />
        </div>
      </form>
      {showMessage && (
        <MessagePopup
          title={messageContent.title}
          message={messageContent.message}
          onClose={() => setShowMessage(false)}
        />
      )}
    </div>
  );
};

export default AccountCreationForm;
