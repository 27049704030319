// src/pages/TermsOfUse.js
import React, { useEffect, useState } from 'react';
import { client } from '../prismicConfiguration';
import { RichText } from 'prismic-reactjs';
import useAuth from '../hooks/useAuth';
import Menu from '../components/Menu';
import PrivacyPolicySkeleton from '../components/PrivacyPolicySkeleton';
import H2 from '../components/H2/H2';
import Footer from '../components/Footer';
import './PrivacyPolicy.css'; // Reuse PrivacyPolicy CSS if similar styling is required

const TermsOfUse = () => {
  const { isLoggedIn, isLoading: authLoading } = useAuth();
  const [termsData, setTermsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTermsData = async () => {
      try {
        const response = await client.getByType('terms_of_use');
        if (response.results.length > 0) {
          // Assuming the first document is the one you need
          setTermsData(response.results[0].data);
        }
      } catch (error) {
        console.error('Error fetching terms of use:', error);
      }
      setIsLoading(false);
    };

    fetchTermsData();
  }, []);

  if (authLoading || isLoading) {
    return <PrivacyPolicySkeleton />;
  }

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="privacy-policy-header">
        <div className="privacy-policy-header-container">
          {termsData && <H2 text={RichText.asText(termsData.title)} textColor="purple" />}
        </div>
      </div>
      <div className="privacy-policy-content">
        {termsData && <RichText render={termsData.content} />}
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
