// src/pages/LoggedInHome.js
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { getAuth } from "firebase/auth";
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
  doc,
  getDoc,
  addDoc,
  deleteDoc,
  where
} from "firebase/firestore";
import useProtectedRoute from "../hooks/useProtectedRoute";
import Menu from "../components/Menu";
import H1 from '../components/H1/H1';
import WeeklyHighlights from '../components/WeeklyHighlights';
import Footer from "../components/Footer";
import MuralItemSkeleton from '../components/MuralItemSkeleton';
import MuralItem from "../components/MuralItem/MuralItem";
import H2 from '../components/H2/H2';
import './Entrepreneurs.css';
import 'react-loading-skeleton/dist/skeleton.css';

const ITEMS_PER_PAGE = 6;

// Utility to remove URLs from strings
const sanitizeText = (text) => {
  return text.replace(/https?:\/\/\S+/g, '[link removido]');
};

function useCurrentUser(auth) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    async function fetchCurrentUser() {
      if (auth.currentUser) {
        const userRef = doc(db, "users", auth.currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setCurrentUser(userSnap.data());
        }
      }
    }

    fetchCurrentUser();
  }, [auth.currentUser]);

  return currentUser;
}

const LoggedInHome = () => {
  const navigate = useNavigate();
  const isLoggedIn = useProtectedRoute();
  const auth = getAuth();
  const currentUser = useCurrentUser(auth);
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserData = useCallback(async (userId) => {
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      let userData = userSnap.data();
      // Sanitize the aboutBusiness and description fields if they exist
      userData.aboutBusiness = userData.aboutBusiness ? sanitizeText(userData.aboutBusiness) : '';
      userData.description = userData.description ? sanitizeText(userData.description) : '';
      return userData;
    }
    return {};
  }, []);

  const checkIfFavorited = useCallback(async (itemId) => {
    if (!auth.currentUser) return false;

    const favoritesRef = collection(db, "favorites");
    const q = query(
      favoritesRef,
      where("userId", "==", auth.currentUser.uid),
      where("muralItemId", "==", itemId),
    );
    const snapshot = await getDocs(q);
    return !snapshot.empty;
  }, [auth.currentUser]);

  const toggleFavorite = useCallback(async (itemId) => {
    if (!auth.currentUser) return;

    const favoritesRef = collection(db, "favorites");
    const q = query(
      favoritesRef,
      where("userId", "==", auth.currentUser.uid),
      where("muralItemId", "==", itemId),
    );
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      await deleteDoc(snapshot.docs[0].ref);
    } else {
      await addDoc(favoritesRef, { userId: auth.currentUser.uid, muralItemId: itemId });
    }

    const updatedItems = items.map((item) => ({
      ...item,
      isFavorite: item.id === itemId ? !item.isFavorite : item.isFavorite
    }));

    setItems(updatedItems);
  }, [auth.currentUser, items]);

  useEffect(() => {
    const fetchItems = async () => {
      if (!isLoggedIn) return;
      setIsLoading(true);
      const q = query(
        collection(db, "mural"),
        orderBy("createdAt", "desc"),
        limit(ITEMS_PER_PAGE)
      );
      const snapshot = await getDocs(q);

      const fetchedItems = await Promise.all(snapshot.docs.map(async (doc) => {
        const userData = await fetchUserData(doc.data().userId);
        const isFavorited = await checkIfFavorited(doc.id);

        return {
          id: doc.id,
          ...doc.data(),
          description: sanitizeText(doc.data().description), // Sanitize description
          userName: userData ? (userData.institutionName || userData.firstName) : "Anônimo",
          userAvatar: userData ? userData.avatar : undefined,
          userCity: userData && userData.address ? userData.address.city : "n/a",
          userState: userData && userData.address ? userData.address.state : "n/a",
          isFavorite: isFavorited,
        };
      }));

      setItems(fetchedItems);
      setIsLoading(false);
    };

    fetchItems();
  }, [isLoggedIn, checkIfFavorited, fetchUserData]);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="ads-wall-container">
        <H1 text={`Bem vindo(a), ${currentUser ? currentUser.firstName || currentUser.institutionName : "Usuário"} :)`} bgColor="purple" fontColor="white" />
        <H2 text="Itens sugeridos pra você" textColor="purple" />
        <div className="ads-wall-grid">
          {isLoading ? (
            Array.from({ length: 3 }, (_, index) => <MuralItemSkeleton key={index} />)
          ) : (
            items.slice(0, 3).map((item) => (
              <MuralItem key={item.id} item={item} onToggleFavorite={() => toggleFavorite(item.id)} onNavigate={navigate} />
            ))
          )}
        </div>
        <hr className="horizontal-rule" />
        <H2 text="Últimas da Rede" textColor="purple" />
        <div className="ads-wall-grid">
          {isLoading ? (
            Array.from({ length: 6 }, (_, index) => <MuralItemSkeleton key={index} />)
          ) : (
            items.slice(3, 9).map((item) => (
              <MuralItem key={item.id} item={item} onToggleFavorite={() => toggleFavorite(item.id)} onNavigate={navigate} />
            ))
          )}
        </div>
      </div>
      <WeeklyHighlights showJoinUsButton={true} />
      <Footer />
    </>
  );
};

export default LoggedInHome;