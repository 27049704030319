// src/components/UsersManagement.js
import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import Papa from 'papaparse';
import './UsersManagement.css';

const UsersManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editUserId, setEditUserId] = useState(null);
  const [editFormData, setEditFormData] = useState({});

  useEffect(() => {
    const fetchUsers = async () => {
      const userCollectionRef = collection(db, "users");
      const userData = await getDocs(userCollectionRef);
      setUsers(userData.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchUsers();
  }, []);

  const handleVerifiedChange = event => {
    setEditFormData({ ...editFormData, verified: event.target.checked });
  };

  const handleEditClick = user => {
    setEditUserId(user.id);
    setEditFormData(user);
  };

  const handleFormChange = event => {
    setEditFormData({ ...editFormData, [event.target.name]: event.target.value });
  };

  const handleCancelEdit = () => {
    setEditUserId(null);
  };

  const handleUpdate = async () => {
    const userDocRef = doc(db, "users", editUserId);
    await updateDoc(userDocRef, editFormData);
    setUsers(users.map(user => (user.id === editUserId ? { ...user, ...editFormData } : user)));
    setEditUserId(null);
  };

  const handleDelete = async (userId) => {
    if (window.confirm("Você tem certeza que deseja deletar este usuário? Esta ação é irreversível.")) {
      try {
        const userDocRef = doc(db, "users", userId);
        await deleteDoc(userDocRef);
        setUsers(users.filter((user) => user.id !== userId));
      } catch (error) {
        console.error("Error deleting user: ", error);
      }
    }
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleExport = () => {
    const csv = Papa.unparse(users);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'users_data.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredUsers = users.filter(user =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="user-management">
      <h2>User Management</h2>
      {editUserId ? (
        <div className="edit-form">
          <label htmlFor="verified"><input type="checkbox" name="verified" checked={editFormData.verified || false} onChange={handleVerifiedChange} /> Verified</label>
          <input type="text" name="firstName" placeholder="First Name" value={editFormData.firstName || ''} onChange={handleFormChange} />
          <input type="text" name="lastName" placeholder="Last Name" value={editFormData.lastName || ''} onChange={handleFormChange} />
          <input type="text" name="email" placeholder="Email" value={editFormData.email || ''} onChange={handleFormChange} />
          <input type="text" name="mobile" placeholder="Mobile" value={editFormData.mobile || ''} onChange={handleFormChange} />
          <input type="date" name="birthDate" placeholder="Birth Date" value={editFormData.birthDate || ''} onChange={handleFormChange} />
          <input type="text" name="address" placeholder="Address" value={editFormData.address || ''} onChange={handleFormChange} />
          <input type="text" name="city" placeholder="City" value={editFormData.city || ''} onChange={handleFormChange} />
          <input type="text" name="state" placeholder="State" value={editFormData.state || ''} onChange={handleFormChange} />
          <input type="text" name="country" placeholder="Country" value={editFormData.country || ''} onChange={handleFormChange} />
          <input type="text" name="zipCode" placeholder="Zip Code" value={editFormData.zipCode || ''} onChange={handleFormChange} />
          <input type="text" name="avatar" placeholder="Avatar URL" value={editFormData.avatar || ''} onChange={handleFormChange} />
          <button onClick={handleUpdate}>Salvar</button>
          <button className="voltar" onClick={handleCancelEdit}>Voltar</button>
        </div>
      ) : (
        <>
          <div className="controls">
            <input 
              type="text"
              placeholder="Procurar por nome"
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
            <button className="export-button" onClick={handleExport}>Exportar usuários</button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr key={user.id}>
                  <td>{user.firstName} {user.lastName}</td>
                  <td>{user.email}</td>
                  <td>
                    <button onClick={() => handleEditClick(user)}>Editar</button>
                    <button className="excluir" onClick={() => handleDelete(user.id)}>Excluir</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default UsersManagement;

