// src/components/MainHero.js
import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button/Button';
import './MainHero.css'; // Import the CSS file for styling

const MainHero = () => {
  return (
    <div className="main-hero-container">
      <div className="grid grid-cols-12 gap-4 max-w-7xl w-full h-[557px]">
        <div className="main-hero-left-block">
          <h1>
            <div className="tag bg-yellow txt-purple p-2 rounded-tr-md leading-10">A Rede Refugia</div> é mais do que uma simples plataforma; somos uma comunidade, formamos uma Rede de Oportunidades.
          </h1>
          <p className="main-hero-nossa-missao">Nossa missão é fortalecer a <strong>colaboração mútua</strong> entre pessoas migrantes, refugiados e organizações.</p>
          <Link to="/criar-conta" className="join-us-button-link">
            <Button text="Junte-se a nós" variant="solid" />
          </Link>
        </div>
        <div className="main-hero-right-block">
          <img src="/img/destaque-01.png" alt="Mulher e menina" />
        </div>
      </div>
    </div>
  );
}

export default MainHero;