// src/pages/MySavedItems.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth';
import { collection, query, getDocs, doc, getDoc, addDoc, deleteDoc, where } from 'firebase/firestore';
import useProtectedRoute from '../hooks/useProtectedRoute';
import Menu from '../components/Menu';
import H1 from '../components/H1/H1'; 
import Button from '../components/Button/Button';
import Footer from '../components/Footer';
import MuralItemSkeleton from '../components/MuralItemSkeleton';
import MuralItem from "../components/MuralItem/MuralItem";
import './AdsWall.css'; 

const ITEMS_PER_PAGE = 16;

const MySavedItems = () => {
  const navigate = useNavigate();
  const isLoggedIn = useProtectedRoute();
  const [isLoading, setIsLoading] = useState(true);
  const [savedItems, setSavedItems] = useState([]);

  const auth = getAuth();

  const fetchUserData = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    return userSnap.exists() ? userSnap.data() : {};
  };

  const toggleFavorite = async (itemId) => {
  const user = auth.currentUser;
  if (!user) return;

  const favoritesRef = collection(db, 'favorites');
  const q = query(favoritesRef, where('userId', '==', user.uid), where('muralItemId', '==', itemId));
  const snapshot = await getDocs(q);

  if (!snapshot.empty) {
    // If already favorited, remove it
    await deleteDoc(snapshot.docs[0].ref);

    // Filter out the unfavorited item from the savedItems state
    setSavedItems(prevItems => prevItems.filter(item => item.id !== itemId));
  } else {
    // If not favorited, add it (this part might not be needed in the context of MySavedItems)
    await addDoc(favoritesRef, { userId: user.uid, muralItemId: itemId });

    // Optionally, update state if adding to favorites is handled here
  }
};


  useEffect(() => {
    const fetchSavedItems = async () => {
      const user = auth.currentUser;
      if (user) {
        const savedItemsRef = collection(db, 'favorites');
        const q = query(savedItemsRef, where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const items = [];
        for (const docSnapshot of querySnapshot.docs) {
          const muralItemRef = doc(db, 'mural', docSnapshot.data().muralItemId);
          const muralItemSnapshot = await getDoc(muralItemRef);
          if (muralItemSnapshot.exists()) {
            // Fetch user data for each saved item
            const userData = await fetchUserData(muralItemSnapshot.data().userId);
            items.push({
              id: muralItemSnapshot.id,
              ...muralItemSnapshot.data(),
              userName: userData.institutionName || userData.firstName,
              userAvatar: userData.avatar,
              userCity: userData.address.city,
              userState: userData.address.state,
              isFavorite: true // Since it's a saved item
            });
          }
        }
        setIsLoading(false);
        setSavedItems(items);
      }
    };
  
    if (isLoggedIn) {
      fetchSavedItems();
    }
  }, [isLoggedIn, auth.currentUser]); 

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="ads-wall-container">
        <div className="flex flex-row sm:flex-row justify-between items-left">
          <H1 text="Itens salvos" bgColor="purple" fontColor="white" />
          <Link to="/mural-da-rede" className="back-to-mural-link">
            <span>&lt; Voltar para o mural</span>
          </Link>
        </div>
        <div className="mt-4 mb-10 flex flex-row sm:flex-row justify-center sm:justify-start sm:space-x-0">
          <Link to="/meus-itens-salvos">
            <Button text="Ofertas e demandas" variant="solid" className="w-[210px] sm:px-4" />
          </Link>
          <Link to="/meus-itens-salvos-empreendedores">
            <Button text="Empreendedores"variant="transparent" />
          </Link>
        </div>
        <div className="ads-wall-grid">
          {isLoading ? (
            <MuralItemSkeleton count={ITEMS_PER_PAGE} />
          ) : (
            savedItems.map(item => (
              <MuralItem key={item.id} item={item} onToggleFavorite={toggleFavorite} onNavigate={navigate} />
            ))
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MySavedItems;
