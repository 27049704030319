// src/pages/Login.js
import React, { useState } from 'react';
import Menu from '../components/Menu';
import LoginForm from '../components/LoginForm';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  const handleLoginSuccess = (id) => {
    setUserId(id);
    navigate('/home-logado');
  };

  return (
    <>
      <Menu isLoggedIn={!!userId} />
      <div className="login-page-container">
        <LoginForm onLoginSuccess={handleLoginSuccess} />
      </div>
      <Footer />
    </>
  );
}

export default Login;
