// src/components/Sidebar.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { client } from '../prismicConfiguration';
import backArrow from '../assets/seta-lista-blog.svg';

const Sidebar = ({ searchTerm, setSearchTerm, handleSearch, categories, filterByCategory, latestPosts }) => {
  const [usefulServices, setUsefulServices] = useState([]);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("pt-BR", options);
  };

  useEffect(() => {
    const fetchUsefulServices = async () => {
      const response = await client.getAllByType('servicos');
      if (response) {
        setUsefulServices(response);
      }
    };

    fetchUsefulServices();
  }, []);

  const isUrl = (text) => {
    return /^(http|https):/.test(text) || text.includes('<a');
  };

  const renderLinkOrText = (text) => {
    if (isUrl(text)) {
      if (text.includes('<a')) {
        return <span dangerouslySetInnerHTML={{ __html: text }} />;
      }
      return <a href={text} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">{text}</a>;
    } else {
      return <span className="text-gray-600">{text}</span>;
    }
  };

  return (
    <div className="w-80 border-0">
      {/* Search bar */}
      <div className="mb-7 w-full">
        <form onSubmit={handleSearch} className="flex">
          <input 
            type="text" 
            placeholder="Buscar no blog" 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow p-3 h-13 text-base font-sourceSansPro border border-gray-300 border-r-0 rounded-tl-xl rounded-bl-xl focus:outline-none"
          />
          <button 
            type="submit" 
            className="w-13 h-13 px-4 bg-blue-refugia hover:bg-pink-refugia rounded-tr-xl rounded-br-xl flex items-center justify-center"
            aria-label="Search"
          >
            <img src="/img/search.svg" alt="Search" className="w-6 h-6" />
          </button>
        </form>
      </div>

      {/* Latest Publications Block */}
      {latestPosts && latestPosts.filter(post => post.uid !== 'servicos-uteis').length > 0 && (
        <div className="bg-white mb-5">
          <h3 className="font-sourceSansPro text-xl font-bold text-gray-800 mb-4">Últimas publicações</h3>
          {latestPosts.filter(post => post.uid !== 'servicos-uteis').map((post, index) => (
            <Link to={`/blog/${post.uid}`} className="flex items-center" style={{ textDecoration: 'none', color: 'inherit' }}>
              <div key={post.id} className="flex items-center mb-4">
                <div className="w-[72px] h-[72px] min-w-[72px] min-h-[72px] overflow-hidden flex justify-center items-center rounded-lg mr-4 bg-no-repeat bg-center bg-cover"
                     style={{ backgroundImage: `url(${post.image})`, backgroundSize: 'cover' }}>
                </div>
                <div>
                  <p className="font-sourceSansPro text-base font-semibold">{post.title}</p>
                  <p className="font-sourceSansPro text-sm">{formatDate(post.date)}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      )}

      {/* Categories Block */}
      <div className="bg-white p-8 border border-gray-300 rounded-xl mb-5">
        <h3 className="font-sourceSansPro text-xl font-bold mb-4 text-purple">Categorias</h3>
        <ul className="list-none p-0">
          {Object.entries(categories).map(([id, name]) => (
            <li 
              key={id} 
              onClick={() => filterByCategory(id)}
              className="cursor-pointer mb-1 text-pink-600 font-sourceSansPro text-base capitalize"
            >
              <p className="font-semibold flex items-center"><img src={backArrow} alt="Back" className="mr-2" /> {name}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* Useful Services Block */}
      <div className="bg-white p-8 border border-gray-300 rounded-xl mb-5">
        <h3 className="font-sourceSansPro text-xl font-bold mb-4 text-purple">Serviços Úteis</h3>
        <ul className="list-none p-0">
          {usefulServices.map((service, index) => (
            <li key={index} className="mb-1 text-pink-600 font-sourceSansPro text-base border-b border-gray-300 pb-2 pt-2">
              <p className="font-semibold">{service.data.nome_do_servico[0].text}</p>
              {service.data.telefone_or_link && service.data.telefone_or_link[0].text && renderLinkOrText(service.data.telefone_or_link[0].text)}
            </li>
          ))}
          <a className="mt-4 block text-blue-600 text-sm hover:text-blue-700" href="/blog/servicos-uteis">Saiba mais</a>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
