// src/components/NossosValores.js
import React from 'react';
import H1 from './H1/H1'; 
import './NossosValores.css'; // Import the CSS file for styling

const NossosValores = () => {
  return (
    <div className="nossos-valores bg-yellow-500">
      <div className="content-area py-12">
        <div className="title valores mb-8">
          <H1 text="Nossos valores" bgColor="yellow" fontColor="white" />
        </div>
        <div className="first-line grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="image-container flex flex-col items-center">
            <img src="/img/valores-impacto.png" alt="Impacto social" className="mb-2" />
            <span>Impacto social</span>
          </div>
          <div className="image-container flex flex-col items-center">
            <img src="/img/valores-acao.png" alt="Ação humanizada" className="mb-2" />
            <span>Ação humanizada</span>
          </div>
          <div className="image-container flex flex-col items-center">
            <img src="/img/valores-inovacao.png" alt="Inovação social" className="mb-2" />
            <span>Inovação social</span>
          </div>
          <div className="image-container flex flex-col items-center">
            <img src="/img/valores-defesa.png" alt="Defesa dos direitos humanos" className="mb-2" />
            <span>Defesa dos direitos humanos</span>
          </div>
        </div>
        <div className="second-line grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          <div className="image-container flex flex-col items-center">
            <img src="/img/valores-valorizacao.png" alt="Valorização da diversidade humana" className="mb-2" />
            <span>Valorização da diversidade humana</span>
          </div>
          <div className="image-container flex flex-col items-center">
            <img src="/img/valores-promocao.png" alt="Promoção do trabalho decente" className="mb-2" />
            <span>Promoção do trabalho decente</span>
          </div>
          <div className="image-container flex flex-col items-center">
            <img src="/img/valores-sustentabilidade.png" alt="Sustentabilidade (social, ambiental, financeira e cultural)" className="mb-2" />
            <span>Sustentabilidade (social, ambiental, financeira e cultural)</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NossosValores;