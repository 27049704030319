// src/pages/MySavedItemsEntrepreneurs.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import useProtectedRoute from '../hooks/useProtectedRoute';
import Menu from '../components/Menu';
import H1 from '../components/H1/H1';
import Button from '../components/Button/Button';
import Footer from '../components/Footer';
import EntrepreneurCard from '../components/EntrepreneurCard/EntrepreneurCard';
import EntrepreneurSkeleton from '../components/EntrepreneurSkeleton';
import EntrepreneurDetailsPopup from '../components/EntrepreneurDetailsPopup';

const ITEMS_PER_PAGE = 16;

const MySavedItemsEntrepreneurs = () => {
  const isLoggedIn = useProtectedRoute();
  const [loading, setLoading] = useState(true);
  const [savedEntrepreneurs, setSavedEntrepreneurs] = useState([]);
  const [selectedEntrepreneur, setSelectedEntrepreneur] = useState(null);
  const [authorInfo, setAuthorInfo] = useState(null);

  const auth = getAuth();

  useEffect(() => {
    const fetchSavedEntrepreneurs = async () => {
      const user = auth.currentUser;
      if (user) {
        const savedItemsRef = collection(db, 'fav_entrepreneur');
        const q = query(savedItemsRef, where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const items = [];
        for (const docSnapshot of querySnapshot.docs) {
          const entrepreneurRef = doc(db, 'entrepreneurs', docSnapshot.data().entrepreneurId);
          const entrepreneurSnapshot = await getDoc(entrepreneurRef);
          if (entrepreneurSnapshot.exists()) {
            const entrepreneurData = entrepreneurSnapshot.data();
            const userData = await fetchAuthorData(entrepreneurData.userId);
            items.push({
              id: entrepreneurSnapshot.id,
              ...entrepreneurData,
              userName: userData?.firstName || 'Unknown',
              userAvatar: userData?.avatar || null,
              isFavorite: true // Since it's a saved item
            });
          }
        }
        setLoading(false);
        setSavedEntrepreneurs(items);
      }
    };
    fetchSavedEntrepreneurs();
  }, [isLoggedIn, auth.currentUser]);

  const fetchAuthorData = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    return userSnap.exists() ? userSnap.data() : null;
  };

  useEffect(() => {
    if (selectedEntrepreneur) {
      fetchAuthorData(selectedEntrepreneur.userId).then(data => {
        setAuthorInfo(data);
      });
    }
  }, [selectedEntrepreneur]);

  const onShowDetails = (entrepreneur) => {
    setSelectedEntrepreneur(entrepreneur);
  };

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="ads-wall-container">
        <div className="flex flex-row sm:flex-row justify-between items-center">
          <H1 text="Itens salvos" bgColor="purple" fontColor="white" />
          <Link to="/mural-da-rede" className="back-to-mural-link">
            <span>&lt; Voltar para o mural</span>
          </Link>
        </div>
        <div className="mt-4 mb-10 flex flex-row sm:grid-cols-2 sm:flex-row justify-center sm:justify-start sm:space-x-0">
          <Link to="/meus-itens-salvos">
            <Button text="Ofertas e demandas" variant="transparent" />
          </Link>
          <Link to="/meus-itens-salvos-empreendedores">
            <Button text="Empreendedores" variant="solid" />
          </Link>
        </div>
        {loading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <EntrepreneurSkeleton count={ITEMS_PER_PAGE} />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {savedEntrepreneurs.map(entrepreneur => (
              <EntrepreneurCard
                key={entrepreneur.id}
                entrepreneur={entrepreneur}
                onShowDetails={() => onShowDetails(entrepreneur)}
              />
            ))}
          </div>
        )}
      </div>
      {selectedEntrepreneur && (
        <EntrepreneurDetailsPopup
          entrepreneur={selectedEntrepreneur}
          onClose={() => setSelectedEntrepreneur(null)}
          authorInfo={authorInfo}
        />
      )}
      <Footer />
    </>
  );
};

export default MySavedItemsEntrepreneurs;
