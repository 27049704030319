// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; 

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD2edAHNHg5JtkFM5s3qzniLl8VdrsNAto",
  authDomain: "rede-refugia-dev.firebaseapp.com",
  projectId: "rede-refugia-dev",
  storageBucket: "rede-refugia-dev.appspot.com",
  messagingSenderId: "441226804633",
  appId: "1:441226804633:web:189630b2991f478bd02708",
  measurementId: "G-4FVG13KB8K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
