// src/compoments/HeroSlide.js
import React from 'react';
import { Link } from 'react-router-dom';
import Button from './Button/Button';

const HeroSlide = ({ post, isActive, isFading }) => {
  const fadeClass = isFading ? 'opacity-0' : 'opacity-100';
  const activeClass = isActive ? 'flex' : 'hidden';

  return (
    <div className={`absolute inset-0 transition-opacity duration-500 ${fadeClass} ${activeClass} flex justify-between items-center`}>
      {/* Mobile Design */}
      <div className="flex flex-col items-center w-full h-full md:hidden">
        <img src={post.image} alt={post.title} className="w-80 -mt-80" />
        <div className="flex flex-col mt-4 px-8">
          <h2 className="text-2xl font-bold text-white mb-2">{post.title}</h2>
          <p className="text-white text-sm font-normal mb-4">{post.description}</p>
          <Link to={post.url} className="inline-block">
            <Button text="Leia mais" variant="solid" />
          </Link>
        </div>
      </div>
      
      {/* Desktop Design */}
      <div className="hidden md:flex flex-1 flex-col justify-center ml-8 lg:ml-34 mr-8 lg:mr-32 mt-9 mb-9">
        <h2 className="text-5xl font-bold text-white max-w-[432px] mb-4">{post.title}</h2>
        <p className="text-white text-base font-normal max-w-[432px] mb-7">{post.description}</p>
        <Link to={post.url} className="inline-block">
          <Button text="Leia mais" variant="solid" />
        </Link>
      </div>
      <div className="hidden md:flex flex-none mt-11 mr-8 lg:mr-32">
        <img src={post.image} alt={post.title} className="w-auto max-w-[400px]" />
      </div>
    </div>
  );
};

export default HeroSlide;