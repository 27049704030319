/* global google */
// src/components/MuralItem/MuralItem.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { addDoc, collection, getFirestore, query, where, getDocs, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { loadGoogleMapsScript } from '../../utils/loadGoogleMapsScript';
import MuralItemDetailsPopup from '../MuralItemDetailsPopup';
import DeleteMuralItemPopup from '../DeleteMuralItemPopup';
import EditItemPopup from '../EditItemPopup';
import Button from '../Button/Button';
import H3 from '../H3/H3';
import Avatar from '../Avatar/Avatar';

// Icons and assets imports (replace with actual paths)
import messageIcon from '../../assets/ico-message.svg';
import heartIconOver from '../../assets/ico-coracao-over.svg';
import heartIcon from '../../assets/ico-favorito.svg';
import deleteIcon from '../../assets/ico-excluir.svg';
import iconMoradia from '../../assets/ico-azul-moradia.svg';
import iconAlimentacao from '../../assets/ico-azul-alimentacao.svg';
import iconTrocas from '../../assets/ico-azul-trocas.svg';
import iconTransporte from '../../assets/ico-azul-transporte.svg';
import iconDocumentacao from '../../assets/ico-azul-documentacao.svg';
import iconEmprego from '../../assets/ico-azul-emprego.svg';
import iconComunicacao from '../../assets/ico-azul-comunicacao.svg';
import iconTreinamento from '../../assets/ico-azul-treinamento.svg';
import iconSaude from '../../assets/ico-azul-saude.svg';
import iconOutros from '../../assets/ico-azul-outros.svg';
import locationIcon from '../../assets/ico-km-distancia.svg';

// Map category names to icon paths
const categoryIcons = {
  moradia: iconMoradia,
  alimentacao: iconAlimentacao,
  trocas: iconTrocas,
  transporte: iconTransporte,
  documentacao: iconDocumentacao,
  emprego: iconEmprego,
  comunicacao: iconComunicacao,
  treinamento: iconTreinamento,
  saude: iconSaude,
  outros: iconOutros,
};

// Mapa de categorias com acentuação
const categoryNamesWithAccent = {
  moradia: 'Moradia',
  alimentacao: 'Alimentação',
  trocas: 'Trocas',
  transporte: 'Transporte',
  documentacao: 'Documentação',
  emprego: 'Emprego',
  comunicacao: 'Comunicação',
  treinamento: 'Treinamento',
  saude: 'Saúde',
  outros: 'Outros',
};

const MuralItem = ({ item, onToggleFavorite, removeItemFromState }) => {
  const auth = getAuth();
  const db = getFirestore(); // Initialize Firestore
  const currentUser = auth.currentUser;
  const navigate = useNavigate(); // Initialize useNavigate
  const [isHeartHovered, setIsHeartHovered] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [authorInfo, setAuthorInfo] = useState(null);
  const [editableItem, setEditableItem] = useState(null);
  const [showPublishPopup, setShowPublishPopup] = useState(false);

  const [distance, setDistance] = useState(null);

  useEffect(() => {
    const calculateDistance = async () => {
      if (!item.endereco || !item.numero || !item.bairro || !item.cidade || !item.estado) {
        setDistance('N/I');
        return;
      }
      try {
        await loadGoogleMapsScript();
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async position => {
            const { latitude, longitude } = position.coords;
            const geocoder = new google.maps.Geocoder();
            const address = `${item.endereco}, ${item.numero}, ${item.bairro}, ${item.cidade}, ${item.estado}, Brazil`;
            console.log(`Geocoding address: ${address}`);

            geocoder.geocode({ address }, (results, status) => {
              if (status === 'OK' && results[0]) {
                const latLng = results[0].geometry.location;
                console.log(`Geocoded Latitude and Longitude: ${latLng}`);
                const distanceService = new google.maps.DistanceMatrixService();
                distanceService.getDistanceMatrix({
                  origins: [latLng],
                  destinations: [{ lat: latitude, lng: longitude }],
                  travelMode: 'DRIVING',
                }, (response, status) => {
                  if (status === 'OK' && response.rows[0].elements[0].status === 'OK') {
                    const dist = response.rows[0].elements[0].distance.text;
                    console.log(`Calculated Distance: ${dist}`);
                    setDistance(dist);
                  } else {
                    console.error(`Distance calculation failed: ${status}`);
                    setDistance('Distance not available');
                  }
                });
              } else {
                console.error(`Geocode was not successful: ${status}`);
                setDistance('Address not found');
              }
            });
          }, (error) => {
            console.error(`Error obtaining location: ${error.message}`);
            setDistance('Location access denied');
          }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
          });
        } else {
          console.error('Geolocation is not supported by this browser.');
          setDistance('Geolocation is not supported by this browser.');
        }
      } catch (error) {
        console.error(`Error loading Google Maps script: ${error}`);
        setDistance('Failed to load Google Maps');
      }
    };

    calculateDistance();
  }, [item.endereco, item.numero, item.bairro, item.cidade, item.estado]);


  useEffect(() => {
    const fetchAuthorInfo = async () => {
      const userRef = doc(db, "users", item.userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setAuthorInfo(userSnap.data());
      }
    };

    fetchAuthorInfo();
  }, [item.userId, db]);

  const truncateDescription = (description) => {
    if (description.length > 240) {
      return description.substring(0, 240) + '...';
    }
    return description;
  };

  const handleEditClick = () => {
      setEditableItem(item);
      setShowPublishPopup(true);
  };

  const handleDeleteClick = () => {
    setShowDeletePopup(true);
  };

  const confirmDeletion = async () => {
    try {
      const muralItemRef = doc(db, "mural", item.id);
      await deleteDoc(muralItemRef);
      setShowDeletePopup(false);
      removeItemFromState(item.id); // Update the state in AdsWall
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleLeiaMaisClick = () => {
    setShowPopup(true);
  };

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };

  // Check if the item belongs to the current logged-in user
  const isCurrentUser = currentUser && item.userId === currentUser.uid;

  // Format date for display outside the handleResponderClick function
  const formattedDate = item.createdAt 
    ? new Intl.DateTimeFormat('en-GB').format(item.createdAt.toDate())
    : 'Data não disponível';

  const handleResponderClick = async () => {
    if (!currentUser) return;
  
    // Fetch conversations that include the current user
    const conversationsQuery = query(
      collection(db, "conversations"),
      where("users", "array-contains", currentUser.uid)
    );
  
    const conversationsSnapshot = await getDocs(conversationsQuery);
    let existingConversationId = null;
  
    // Filter conversations to find any that include the other user
    conversationsSnapshot.forEach((doc) => {
      if (doc.data().users.includes(item.userId)) {
        existingConversationId = doc.id;
      }
    });
  
    const initialMessageData = {
      sender: currentUser.uid,
      text: `Olá, tenho interesse nesta ${item.type === 'demand' ? 'Demanda' : 'Oferta'}: ${item.title}`,
      createdAt: new Date()
    };
  
    if (existingConversationId) {
      // Existing conversation found, post the message and navigate to it
      await addDoc(collection(db, `conversations/${existingConversationId}/messages`), initialMessageData);
      navigate('/central-de-mensagens', { state: { selectedConversationId: existingConversationId } });
    } else {
      // No existing conversation, create a new one
      const conversationData = {
        users: [currentUser.uid, item.userId],
        createdAt: new Date()
      };
  
      try {
        // Create a new conversation document in the conversations collection
        const newConversationRef = await addDoc(collection(db, 'conversations'), conversationData);
        
        // Add the initial message to a subcollection within the conversation document
        await addDoc(collection(db, `conversations/${newConversationRef.id}/messages`), initialMessageData);
  
        // Navigate to the newly created conversation
        navigate('/central-de-mensagens', { state: { selectedConversationId: newConversationRef.id } });
      } catch (error) {
        console.error("Error starting conversation: ", error);
      }
    }
  };

  return (
    <div className="bg-white rounded-2xl p-5 grid gap-4 col-span-3">
      <div className="flex justify-between items-start">
          <div className={`px-3 py-3 text-white font-bold ${item.type === 'demand' ? 'bg-pink-500' : 'bg-green-500'} rounded-tr-2xl rounded-br-2xl rounded-bl-2xl`}>
            {item.type === 'demand' ? 'Demanda' : 'Oferta'}
          </div>
          <div className="flex items-center">
              <img src={categoryIcons[item.category]} alt="Category" className="w-7 h-7 rounded-full" />
              <span className="text-blue-800 font-bold ml-2 capitalize">{categoryNamesWithAccent[item.category]}</span>
          </div>
      </div>

      <div className="flex items-center mt-1 justify-between">
        <div className="flex items-center max-w-[50%]">
          <Avatar src={item.userAvatar} alt={item.userName} name={item.userName} />
          <div className="ml-2 text-gray-500">{item.userName}</div>
        </div>
        <div className="flex items-center">
          {distance && distance !== 'Distance not available' && (
            <>
              <img src={locationIcon} alt="Location" className="mural-location-icon" />
              <div className="block item-distance ml-2 text-gray-500">{distance} • </div>
            </>
          )}
          <span className="item-distance text-gray-500">{formattedDate}</span>
        </div>
      </div>



      <H3 text={item.title} textColor="purple" />

      <div className="text-gray-600 -mt-10 min-h-[125px]">{truncateDescription(item.description)}</div>

      <div className="flex mt-2">
        <Button text="Leia mais" variant="transparent" onClick={handleLeiaMaisClick} />
        {!isCurrentUser ? (
          <>
            <Button text="Responder" variant="icon" icon={messageIcon} onClick={handleResponderClick} />
            <button 
              className="w-6 h-6 flex items-center justify-center"
              onMouseEnter={() => setIsHeartHovered(true)}
              onMouseLeave={() => setIsHeartHovered(false)}
              onClick={() => onToggleFavorite(item.id)}
            >
              <img 
                src={isHeartHovered ? heartIconOver : (item.isFavorite ? heartIconOver : heartIcon)}
                alt="Favoritar"
                className="w-6 h-6 mt-7"
              />
            </button>
          </>
        ) : (
          <>
            <Button text="Editar" variant="icon" onClick={handleEditClick} />
            <img 
              src={deleteIcon} 
              alt="Excluir" 
              className="w-5 h-5 cursor-pointer mt-4 ml-14" 
              onClick={handleDeleteClick} 
            />
          </>
        )}
      </div>

      {showPopup && (
        <MuralItemDetailsPopup 
          item={item}
          onClose={() => setShowPopup(false)}
          onSendMessage={handleResponderClick}
          authorInfo={authorInfo}
          distance={distance}  // Passing the calculated distance
          onToggleFavorite={onToggleFavorite}
        />
      )}
      {showPublishPopup && <EditItemPopup item={editableItem} onClose={() => setShowPublishPopup(false)} />}
      {showDeletePopup && (
        <DeleteMuralItemPopup 
          onClose={() => setShowDeletePopup(false)} 
          onConfirm={confirmDeletion}
        />
      )}
    </div>
  );
};

export default MuralItem;
