// src/components/Newsletter.js
import React, { useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, "newsletter"), {
                email: email,
            });
            setEmail('');
            setIsSubscribed(true);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <div className="newsletter newsletter-container">
            <h2>Inscreva-se e fique por dentro das novidades da rede.</h2>
            {!isSubscribed ? (
                <form onSubmit={handleSubmit}>
                    <input 
                        type="email" 
                        placeholder="Digite seu email" 
                        value={email} 
                        className="flex-grow p-3 w-72 h-13 text-base font-sourceSansPro border border-gray-300 border-r-0 rounded-tl-lg rounded-bl-lg focus:outline-none"
                        onChange={(e) => setEmail(e.target.value)}
                        required 
                    />
                    <button className="hover:bg-pink-refugia" type="submit">Inscrever</button>
                </form>
            ) : (
                <p className="success">Obrigado por se inscrever!</p>
            )}
        </div>
    );
};

export default Newsletter;
