import React from 'react';

const HrefLink = ({ href, label, className, children }) => {
  return (
    <a href={href} className={className} target="_blank" rel="noopener noreferrer">
      {children}
      {label}
    </a>
  );
};

export default HrefLink;
