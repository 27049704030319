// src/components/FilterBar/FilterBar.js
import React from 'react';

const FilterBar = ({ selectedType, handleTypeChange, selectedState, handleStateChange }) => {
  return (
    <div className="filter-bar flex flex-wrap items-center justify-between py-4 px-0">
      <div className="flex flex-wrap gap-4 items-center">
        <div className="filter-item">
          <label htmlFor="type-filter">Tipo:</label>
          <select id="type-filter" value={selectedType} onChange={handleTypeChange} className="ml-2">
            <option value="">Todos</option>
            <option value="demand">Demanda</option>
            <option value="offer">Oferta</option>
          </select>
        </div>
        <div className="filter-item">
          <label htmlFor="state-filter">Estado:</label>
          <select id="state-filter" value={selectedState || ''} onChange={handleStateChange} className="ml-2">
            <option value="">Todos</option>
            <option value="AC">Acre</option>
            <option value="AL">Alagoas</option>
            <option value="AP">Amapá</option>
            <option value="AM">Amazonas</option>
            <option value="BA">Bahia</option>
            <option value="CE">Ceará</option>
            <option value="DF">Distrito Federal</option>
            <option value="ES">Espírito Santo</option>
            <option value="GO">Goiás</option>
            <option value="MA">Maranhão</option>
            <option value="MT">Mato Grosso</option>
            <option value="MS">Mato Grosso do Sul</option>
            <option value="MG">Minas Gerais</option>
            <option value="PA">Pará</option>
            <option value="PB">Paraíba</option>
            <option value="PR">Paraná</option>
            <option value="PE">Pernambuco</option>
            <option value="PI">Piauí</option>
            <option value="RJ">Rio de Janeiro</option>
            <option value="RN">Rio Grande do Norte</option>
            <option value="RS">Rio Grande do Sul</option>
            <option value="RO">Rondônia</option>
            <option value="RR">Roraima</option>
            <option value="SC">Santa Catarina</option>
            <option value="SP">São Paulo</option>
            <option value="SE">Sergipe</option>
            <option value="TO">Tocantins</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
