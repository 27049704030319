// src/pages/BlogPost.js
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { client } from "../prismicConfiguration";
import { RichText } from "prismic-reactjs";
import { predicate } from "@prismicio/client";
import useAuth from "../hooks/useAuth";
import Menu from "../components/Menu";
import Sidebar from "../components/Sidebar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import "./BlogPost.css";
import backArrow from '../assets/seta-lista-blog.svg';

const BlogPost = () => {
  const { isLoggedIn, isLoading } = useAuth();
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [latestPosts, setLatestPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categories, setCategories] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesResponse = await client.get({
          predicates: predicate.at('document.type', 'catetories'),
          pageSize: 100,
        });

        if (categoriesResponse && categoriesResponse.results) {
          const categoriesMap = categoriesResponse.results.reduce((acc, category) => {
            if (category.data.category_name && category.data.category_name.length > 0) {
              const categoryName = category.data.category_name[0].text;
              acc[category.id] = categoryName;
            }
            return acc;
          }, {});
          setCategories(categoriesMap);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchPost = async () => {
      const response = await client.getByUID("blog_post", postId);
      if (response) {
        setPost(response);
      }
    };

    // Assuming you fetch posts like this
    const fetchLatestPosts = async () => {
        const response = await client.get({
            predicates: predicate.at('document.type', 'blog_post'),
            pageSize: 6,
            orderings: {
                field: 'document.first_publication_date',
                direction: 'desc'
            }
        });
        if (response && response.results) {
            const posts = response.results.map(post => ({
                id: post.id, // Keep the ID if needed for other purposes
                uid: post.uid, // Ensure this line is here to fetch the UID
                title: RichText.asText(post.data.title),
                image: post.data.add_media?.url || '/path/to/default/image.png',
                date: post.first_publication_date
            }));
    
            setLatestPosts(posts);
        }
    };

    


    fetchPost();
    fetchLatestPosts();
    fetchCategories();
  }, [postId]);

  useEffect(() => {
    if (!post) {
      console.log('Waiting for post data to be available...');
      return;
    }

    console.log('Initializing Disqus for post:', postId);
    const disqusThread = document.getElementById('disqus_thread');

    if (!disqusThread) {
      console.log('Disqus thread element not found');
      return;
    }

    const loadDisqus = () => {
      window.disqus_config = function () {
        this.page.url = window.location.href;
        this.page.identifier = postId;
      };

      const script = document.createElement('script');
      script.src = 'https://rede-refugia.disqus.com/embed.js';
      script.setAttribute('data-timestamp', +new Date());
      script.async = true;

      script.onload = () => {
        if (window.DISQUS) {
          window.DISQUS.reset({
            reload: true,
            config: function () {
              this.page.url = window.location.href;
              this.page.identifier = postId;
            },
          });
        }
      };

      document.body.appendChild(script);
    };

    if (window.DISQUS) {
      window.DISQUS.reset({
        reload: true,
        config: function () {
          this.page.url = window.location.href;
          this.page.identifier = postId;
        },
      });
    } else {
      loadDisqus();
    }

    return () => {
      const script = document.querySelector('script[src="https://rede-refugia.disqus.com/embed.js"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [postId, post]);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/blog?search=${encodeURIComponent(searchTerm)}`);
  };

  const filterByCategory = (categoryId) => {
    navigate(`/blog?category=${categoryId}`);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("pt-BR", options);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!post) {
    return <div>Post not found</div>;
  }

  const categoryName = categories[post.data.category.id] || 'Sem categoria';

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <div className="blogpost-page-container">
        {post && (
          <>
            <div className="blog-post-column">
              <Link to="/blog" className="back-to-list flex items-center">
                <img src={backArrow} alt="Back" className="scale-x-[-1]" /> <span className="ml-2">Voltar para listagem de posts</span>
              </Link>
              <div className="post-category-date">
                <span className="category">{categoryName}</span>
                <span className="post-date">
                  {formatDate(post.first_publication_date)}
                </span>
              </div>
              <h1>{RichText.asText(post.data.title)}</h1>
              <div className="text-base mb-12">{RichText.render(post.data.content)}</div>
              <div id="disqus_thread" className="mt-12"></div>
            </div>
            <div className="mt-10 mr-10">
              <Sidebar 
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                handleSearch={handleSearch}
                categories={categories}
                filterByCategory={filterByCategory}
                latestPosts={latestPosts}
                className="lg:w-1/3 pl-4"
              />
            </div>
          </>
        )}
      </div>
      <Newsletter />
      <Footer />
    </>
  );
}

export default BlogPost;

