import React from 'react';

const MuralItemSkeleton = () => {
  return (
    <div className="bg-white rounded-2xl p-5 gap-4 col-span-3 min-h-[442px]">
      <div className="animate-pulse">
        <div className="h-5 bg-gray-300 rounded w-4/5 mb-4"></div>
        <div className="h-4 bg-gray-300 rounded w-3/5 mb-2"></div>
        <div className="flex items-center">
          <div className="w-10 h-10 bg-gray-300 rounded-full mr-3"></div>
          <div className="flex flex-col space-y-2">
            <div className="h-3 bg-gray-300 rounded w-4/5"></div>
            <div className="h-3 bg-gray-300 rounded w-3/5"></div>
          </div>
        </div>
        <div className="h-5 bg-gray-300 rounded w-9/10 mt-4 mb-2"></div>
        <div className="space-y-2">
          <div className="h-4 bg-gray-300 rounded w-full"></div>
          <div className="h-4 bg-gray-300 rounded w-full"></div>
        </div>
        <div className="h-10 bg-gray-300 rounded w-1/3 mt-4"></div>
      </div>
    </div>
  );
};

export default MuralItemSkeleton;
