import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import H1 from './H1/H1'; 
import H2 from './H2/H2';
import './OportunidadesDemandas.css';

const OportunidadesDemandas = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    setIsLoggedIn(!!auth.currentUser);
  }, [auth.currentUser]);

  const linkUrl = isLoggedIn ? "/mural-da-rede" : "/criar-conta";

  return (
    <div className="bg-[#4563AB] text-white p-10 bg-cover bg-left" style={{ backgroundImage: "url('/img/bg-tipos-oportunidades.svg')" }}>
      <div className="content-area">
        <div className='title-area'>
          {/* Desktop and larger devices */}
          <div className="hidden sm:block" >
            <H1
              text="Quais os tipos de oportunidades<br />e demandas você encontra aqui?"
              bgColor="orange"
              fontColor="white"
            />
          </div>
          {/* Mobile devices */}
          <div className="sm:hidden">
            <H1
              text="Quais os tipos de<br />oportunidades e demandas<br />você encontra aqui?"
              bgColor="orange"
              fontColor="white"
            />
          </div>
          <div className="flex justify-center md:w-2/6 mx-auto">
            <H2 text="São 10 categorias para filtrar suas ofertas e demandas na Rede Refugia." textColor="white" />
          </div>
        </div>
        <div className="icon-grid">
          {/* First line of icons */}
          <a href={linkUrl} className="icon-column"><img src="/img/ico-moradia.svg" alt="Moradia" /><span>Moradia</span></a>
          <a href={linkUrl} className="icon-column"><img src="/img/ico-alimentacao.svg" alt="Alimentação" /><span>Alimentação</span></a>
          <a href={linkUrl} className="icon-column"><img src="/img/ico-trocas.svg" alt="Trocas" /><span>Trocas</span></a>
          <a href={linkUrl} className="icon-column"><img src="/img/ico-transporte.svg" alt="Transporte" /><span>Transporte</span></a>
          <a href={linkUrl} className="icon-column"><img src="/img/ico-documentacao.svg" alt="Documentação" /><span>Documentação</span></a>

          {/* Second line of icons */}
          <a href={linkUrl} className="icon-column"><img src="/img/ico-emprego.svg" alt="Emprego/Renda" /><span>Emprego/Renda</span></a>
          <a href={linkUrl} className="icon-column"><img src="/img/ico-comunicacao.svg" alt="Comunicação" /><span>Comunicação</span></a>
          <a href={linkUrl} className="icon-column"><img src="/img/ico-treinamento.svg" alt="Treinamento" /><span>Treinamento</span></a>
          <a href={linkUrl} className="icon-column"><img src="/img/ico-saude.svg" alt="Saúde" /><span>Saúde</span></a>
          <a href={linkUrl} className="icon-column"><img src="/img/ico-outros.svg" alt="Outros" /><span>Outros</span></a>
        </div>
      </div>
    </div>
  );
};

export default OportunidadesDemandas;
