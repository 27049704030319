// src/components/SelectedFilters.js
import React from 'react';
import './SelectedFilters.css';

const SelectedFilters = ({ selectedCategory, onClearFilters }) => {
  if (!selectedCategory) {
    return null;
  }

  const handleRemoveFilter = (e) => {
    e.preventDefault();
    onClearFilters();
  };

  return (
    <div className="selected-filters-container">
      <div className="selected-filters">
        <span>Filtros selecionados:</span>
        <div className="filter-tag">
          {selectedCategory}
          {/* Use <button> styled as a link */}
          <button className="filter-remove-link" onClick={handleRemoveFilter}>&nbsp;&nbsp;x&nbsp;&nbsp;</button>
        </div>
      </div>
      <div className="clear-filters">
        {/* Use <button> styled as a link */}
        <button className="clear-filters-link" onClick={onClearFilters}>Limpar filtros</button>
      </div>
    </div>
  );
};

export default SelectedFilters;
