import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; 
import H1 from './H1/H1'; 
import './NossosResultados.css'; // Import the CSS file for styling

const NossosResultados = () => {
  const [peopleImpacted, setPeopleImpacted] = useState(0);
  const [projectsSupported, setProjectsSupported] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, 'content', 'nossosResultados');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setPeopleImpacted(data.peopleImpacted);
        setProjectsSupported(data.projectsSupported);
      } else {
        console.log("No such document!");
      }
    };

    fetchData();
  }, []);

  return (
    <div className='nossos-resultados'>
      <div className='content-area'>
        <div className='title-area'>
          <H1 text="Nossos resultados" bgColor="blue" fontColor="white" />
        </div>
        <div className='results-grid'>
          <div className='result-column'>
            <div className="block-content">
              <img src="/img/ico-pessoas-impactadas.svg" alt="Pessoas Impactadas" /> 
              <h2 className='subtitle'>{peopleImpacted}</h2>
              <p className="leading-10">pessoas<br />impactadas</p>
            </div> 
          </div>
          <div className='result-column'>
            <div className="block-content">
              <img src="/img/ico-projetos.svg" alt="Projetos Apoiados" /> 
              <h2 className='subtitle'>{projectsSupported}</h2>
              <p className="leading-10">projetos<br />apoiados</p>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default NossosResultados;
