// src/components/Menu.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import logo from '../assets/logo.svg';
import Button from './Button/Button';
import PublishItemPopup from './PublishItemPopup';
import Avatar from './Avatar/Avatar';
import './Menu.css';

const Menu = ({ isLoggedIn }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [userAvatar, setUserAvatar] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userAddressComplete, setUserAddressComplete] = useState(false);
  const [showPublishPopup, setShowPublishPopup] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();

  // Function to navigate and scroll to the 'Como Funciona' section
  const navigateToComoFunciona = (event) => {
    event.preventDefault();
    removeNoScroll();
    navigate("/", { state: { scrollToComoFunciona: true } });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUserName(userData.firstName || userData.institutionName);
          setUserAvatar(userData.avatar);
          const { address = {} } = userData;
          const requiredFields = ['address', 'city', 'state', 'zipCode'];
          const isAddressComplete = requiredFields.every(field => address[field] && address[field].trim() !== '');
          setUserAddressComplete(isAddressComplete);
        }
      } else {
        setUserAvatar(null);
        setUserName('');
        setUserAddressComplete(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
    if (!isSubMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const removeNoScroll = () => {
    document.body.classList.remove('no-scroll');
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      removeNoScroll();
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  const openPublishPopup = () => {
    if (!userAddressComplete) {
      navigate('/minha-conta', { state: { message: 'Para postar um item no mural, é necessário completar o seu cadastro e endereço.' } });
    } else {
      setShowPublishPopup(true);
    }
  };

  return (
    <div className="menu-outer-container">
      <div className="menu-container">
        <Link to="/" onClick={removeNoScroll}><img src={logo} alt="Logo" className="menu-logo" /></Link>
        <button className="menu-toggle" onClick={toggleSubMenu}>☰</button>
        
        <div className={`menu-links ${isSubMenuOpen ? 'open' : ''}`}>
          {isSubMenuOpen && (
            <button className="menu-close-button" onClick={toggleSubMenu}>×</button>
          )}
          {isLoggedIn && <Link to="/home-logado" className="menu-link" onClick={removeNoScroll}>Painel Inicial</Link>}
          <Link to="/sobre" className="menu-link" onClick={removeNoScroll}>Sobre a Rede</Link>
          <a href="#como-funciona" onClick={navigateToComoFunciona} className="menu-link">Como Funciona</a>
          <Link to="/blog" className="menu-link" onClick={removeNoScroll}>Blog</Link>
          {!isLoggedIn && <a href="https://help.unicef.org/sit-drtv?language=pt-br" target="_blank" rel="noopener noreferrer" className="menu-link" onClick={removeNoScroll}>Doação</a>}
          <div className="menu-link-separator"></div>

          {isLoggedIn ? (
            <>
              <Link to="/mural-da-rede" className="menu-link" onClick={removeNoScroll}>Mural</Link>
              <Link to="/vitrine-de-empreendedores" className="menu-link" onClick={removeNoScroll}>Empreendedores</Link>
              <Button text="Postar item no mural" variant="transparent" onClick={openPublishPopup} />
              <Link to="/central-de-mensagens" className="menu-link message-link" onClick={removeNoScroll}></Link>
              
              <div className="user-submenu-container" onClick={toggleSubMenu}>
                <div className={`user-initial-container ${isSubMenuOpen ? 'move-right' : ''}`}>
                  <Avatar src={userAvatar} alt="User Avatar" name={userName} />
                </div>
                <span className={`user-arrow ${isSubMenuOpen ? 'up' : ''}`}></span>
                {isSubMenuOpen && (
                  <div className="user-expanded-submenu">
                    <span className={`user-greeting ${isSubMenuOpen ? 'fade-in' : ''}`}>Olá, {userName}</span>
                    <Link className="submenu-link" to="/minha-conta" onClick={removeNoScroll}>Minha conta</Link>
                    <Link className="submenu-link" to="/meus-itens-salvos" onClick={removeNoScroll}>Meus itens salvos</Link>
                    <Link className="submenu-link" to="/meus-itens-publicados" onClick={removeNoScroll}>Meus itens criados</Link>
                    <Link className="submenu-link" to="/central-de-mensagens" onClick={removeNoScroll}>Mensagens</Link>
                    <a href="/" className="submenu-link" onClick={handleLogout}>Sair</a>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Link to="/login" className="menu-link" onClick={removeNoScroll}>Login</Link>
              <Link to="/criar-conta" className="menu-link join-us-link" onClick={removeNoScroll}><Button text="Junte-se a nós" variant="solid" /></Link>
              <div className="mb-20 md:md-0 h-80 md:h-0"></div>
            </>
          )}
          {isLoggedIn && showPublishPopup && (
            <PublishItemPopup userId={userId} onClose={() => setShowPublishPopup(false)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;