// src/components/EntrepreneurDetailsPopup.js
import React from 'react';
import H3 from './H3/H3';
import AuthorDetails from './AuthorDetails';
import distanceIcon from '../assets/ico-km-distancia.svg';
import closeBtnImage from '../assets/btn-close.png';
import heartIconOver from '../assets/ico-coracao-over.svg';
import heartIcon from '../assets/ico-favorito.svg';

const categoryNamesWithAccent = {
  moradia: 'Moradia',
  alimentacao: 'Alimentação',
  trocas: 'Trocas',
  transporte: 'Transporte',
  documentacao: 'Documentação',
  emprego: 'Emprego',
  comunicacao: 'Comunicação',
  treinamento: 'Treinamento',
  saude: 'Saúde',
  outros: 'Outros',
};

const EntrepreneurDetailsPopup = ({ entrepreneur, onClose, authorInfo, isFavorite, onToggleFavorite }) => {
  const { category, businessCity, businessState, businessAddress, businessHours, website, instagram, businessPhone } = entrepreneur;
  const categoryName = category ? categoryNamesWithAccent[category] : '';

  // Function to extract Instagram handle from URL
  const extractInstagramHandle = (url) => {
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?instagram\.com\//, '');
  };

  const instagramHandle = instagram ? `@${extractInstagramHandle(instagram)}` : '';

  return (
    <>
      {/* Desktop View */}
      <div className="hidden md:flex fixed inset-0 z-[9999] bg-yellow-300 justify-center items-center">
        <div className="bg-white rounded-xl p-10 flex flex-col relative" style={{ width: '795px', height: '480px' }}>
          <button className="absolute -top-3.5 -right-3.5 w-8 h-8 bg-transparent border-none cursor-pointer p-0" onClick={onClose}>
            <img src={closeBtnImage} alt="Close" />
          </button>

          {/* First Row for Favorite Button */}
          <div className="flex-none w-full mb-5">
            <button
              className="absolute top-10 right-10 w-6 h-6"
              onClick={onToggleFavorite}
            >
              <img
                src={isFavorite ? heartIconOver : heartIcon}
                alt="Favoritar"
                className="w-6 h-6"
              />
            </button>
          </div>

          {/* Second Row for Content */}
          <div className="flex w-full flex-grow">
            <div className="w-3/5 pr-2">
              <div className="flex flex-col justify-between flex-grow">
                {category && (
                  <div className="font-sans text-sm font-bold text-[#EE7911] mb-2">{categoryName}</div>
                )}
                <div className="flex justify-between items-center">
                  <H3 text={entrepreneur.companyName} textColor="purple" />
                  {businessCity && businessState && (
                    <div className="flex items-center -mt-6 pr-8">
                      <img src={distanceIcon} alt="Distance" className="w-4 h-4 mr-1" />
                      <p className="text-sm text-gray-500">{businessCity} - {businessState}</p>
                    </div>
                  )}
                </div>
                <p className="text-base text-gray-600 -mt-5 mb-4">{entrepreneur.aboutBusiness}</p>
                <p className="text-base text-gray-600 mb-2">Endereço: {businessAddress}</p>
                <p className="text-base text-gray-600 mb-5">Horários: {businessHours}</p>
                {/* Links */}
                {website && (
                  <div className="flex items-center mb-3">
                    <img src="/img/ico-url-perfil.svg" alt="" className="w-5 h-5 mr-1.5" />
                    <a href={website} target="_blank" rel="noopener noreferrer" className="text-[#4663AB] font-sans font-bold text-sm">
                      {website}
                    </a>
                  </div>
                )}
                {instagram && (
                  <div className="flex items-center mb-3">
                    <img src="/img/ico-instagram-perfil.svg" alt="" className="w-5 h-5 mr-1.5" />
                    <a href={instagram} target="_blank" rel="noopener noreferrer" className="text-[#4663AB] font-sans font-bold text-sm">
                      {instagramHandle}
                    </a>
                  </div>
                )}
                {businessPhone && (
                  <div className="flex items-center">
                    <img src="/img/ico-telefone-perfil.svg" alt="" className="w-5 h-5 mr-1.5" />
                    <a href={`tel:${businessPhone}`} target="_blank" rel="noopener noreferrer" className="text-[#4663AB] font-sans font-bold text-sm">
                      {businessPhone}
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="w-2/5 mt-5">
              {authorInfo && <AuthorDetails authorInfo={authorInfo} title="Perfil do empreendedor" />}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="md:hidden fixed inset-0 z-[9999] bg-yellow-300 flex justify-center items-center">
        <div className="bg-white rounded-lg p-8 flex flex-col relative w-5/6 h-[90%]">
          <button className="absolute -top-3.5 -right-3.5 w-8 h-8 bg-transparent border-none cursor-pointer p-0" onClick={onClose}>
            <img src={closeBtnImage} alt="Close" />
          </button>

          {/* First Row for Favorite Button */}
          <div className="flex-none w-full mb-5">
            <button
              className="absolute top-10 right-10 w-6 h-6"
              onClick={onToggleFavorite}
            >
              <img
                src={isFavorite ? heartIconOver : heartIcon}
                alt="Favoritar"
                className="w-6 h-6"
              />
            </button>
          </div>

          {/* Second Row for Content */}
          <div className="flex w-full flex-grow flex-col">
            <div className="w-full pr-2">
              <div className="flex flex-col justify-between flex-grow">
                {category && (
                  <div className="font-sans text-sm font-bold text-[#EE7911] mb-2">{categoryName}</div>
                )}
                <div className="flex justify-between items-center">
                  <H3 text={entrepreneur.companyName} textColor="purple" />
                  {businessCity && businessState && (
                    <div className="flex items-center mt-2">
                      <img src={distanceIcon} alt="Distance" className="w-4 h-4 mr-1" />
                      <p className="text-sm text-gray-500">{businessCity} - {businessState}</p>
                    </div>
                  )}
                </div>
                <p className="text-base text-gray-600 mt-2 mb-4">{entrepreneur.aboutBusiness}</p>
                <p className="text-base text-gray-600 mb-2">Endereço: {businessAddress}</p>
                <p className="text-base text-gray-600 mb-5">Horários: {businessHours}</p>
                {/* Links */}
                {website && (
                  <div className="flex items-center mb-3">
                    <img src="/img/ico-url-perfil.svg" alt="" className="w-5 h-5 mr-1.5" />
                    <a href={website} target="_blank" rel="noopener noreferrer" className="text-[#4663AB] font-sans font-bold text-sm">
                      {website}
                    </a>
                  </div>
                )}
                {instagram && (
                  <div className="flex items-center mb-3">
                    <img src="/img/ico-instagram-perfil.svg" alt="" className="w-5 h-5 mr-1.5" />
                    <a href={instagram} target="_blank" rel="noopener noreferrer" className="text-[#4663AB] font-sans font-bold text-sm">
                      {instagramHandle}
                    </a>
                  </div>
                )}
                {businessPhone && (
                  <div className="flex items-center">
                    <img src="/img/ico-telefone-perfil.svg" alt="" className="w-5 h-5 mr-1.5" />
                    <a href={`tel:${businessPhone}`} target="_blank" rel="noopener noreferrer" className="text-[#4663AB] font-sans font-bold text-sm">
                      {businessPhone}
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full mt-5">
              {authorInfo && <AuthorDetails authorInfo={authorInfo} title="Perfil do empreendedor" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EntrepreneurDetailsPopup;