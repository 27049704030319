// src/pages/Blog.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { client } from '../prismicConfiguration';
import { RichText } from 'prismic-reactjs';
import { predicate } from '@prismicio/client';
import Button from '../components/Button/Button';
import useAuth from '../hooks/useAuth';
import Menu from '../components/Menu';
import H1 from '../components/H1/H1'; 
import Hero from '../components/Hero';
import BlogCard from '../components/BlogCard';
import BlogCardSkeleton from '../components/BlogCardSkeleton';
import Sidebar from '../components/Sidebar';
import Newsletter from '../components/Newsletter';
import Footer from '../components/Footer';
import './Blog.css';

const Blog = () => {
  const { isLoggedIn, isLoading } = useAuth();
  const [blogPosts, setBlogPosts] = useState([]);
  const [highlightedPosts, setHighlightedPosts] = useState([]);
  const [categories, setCategories] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);

  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 8; // Number of posts per page

  const postsToShow = filteredPosts.length > 0 ? filteredPosts : blogPosts;

  const indexOfLastPost = currentPage * postsPerPage;
  const currentPosts = postsToShow.slice(0, indexOfLastPost);

  const [loading, setLoading] = useState(true);

  const loadMorePosts = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchTermParam = searchParams.get('search');
    const categoryId = searchParams.get('category');
  
    if (searchTermParam) {
      const filtered = blogPosts.filter(post => 
        RichText.asText(post.data.title).toLowerCase().includes(searchTermParam.toLowerCase())
      );
      setFilteredPosts(filtered);
    } else if (categoryId) {
      const filtered = blogPosts.filter(post => post.data.category.id === categoryId);
      setFilteredPosts(filtered);
    }
  }, [location, blogPosts]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchCategories();
      await fetchBlogPosts();
      await fetchHighlightedPosts(); // New function to fetch highlighted posts
      setLoading(false);
    };

    fetchData();
  }, []);

  const fetchHighlightedPosts = async () => {
    try {
      const highlightsResponse = await client.get({
        predicates: predicate.at('document.type', 'highlights'),
        pageSize: 4, // Adjust as needed
      });

      if (highlightsResponse && highlightsResponse.results) {
        const highlights = highlightsResponse.results.map(highlight => {
          return {
            id: highlight.id,
            title: RichText.asText(highlight.data.title),
            description: RichText.asText(highlight.data.description),
            url: highlight.data.url.url, // assuming the URL field is named 'url'
            image: highlight.data.image.url,
            // add other necessary fields here
          };
        });
  
        setHighlightedPosts(highlights);
      }
    } catch (error) {
      console.error('Error fetching highlighted posts:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const categoriesResponse = await client.get({
        predicates: predicate.at('document.type', 'catetories'),
        pageSize: 100,
      });
  
      if (categoriesResponse && categoriesResponse.results) {
        const categoriesMap = categoriesResponse.results.reduce((acc, category) => {
          if (category.data.category_name && category.data.category_name.length > 0) {
            const categoryName = category.data.category_name[0].text;
            acc[category.id] = categoryName;
          }
          return acc;
        }, {});
  
        setCategories(categoriesMap);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchBlogPosts = async () => {
    const response = await client.get({
      predicates: predicate.at('document.type', 'blog_post'),
      pageSize: 20,
    });
    if (response) {
      const filteredPosts = response.results.filter(
        post => RichText.asText(post.data.title) !== 'Serviços úteis'
      );
      setBlogPosts(filteredPosts);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const filtered = blogPosts.filter(post => 
      RichText.asText(post.data.title).toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredPosts(filtered);
  };

  const filterByCategory = (categoryId) => {
    const filtered = blogPosts.filter(post => post.data.category.id === categoryId);
    setFilteredPosts(filtered);
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <Hero highlightedPosts={highlightedPosts} />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 bg-white shadow-inner">
        <div className="py-6">
          {/* Mobile View: Sidebar before Últimas publicações */}
          <div className="flex flex-col items-center lg:hidden">
            <Sidebar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              handleSearch={handleSearch}
              categories={categories}
              filterByCategory={filterByCategory}
              className="w-full mb-4"
            />

            <div className="block md:none">
              <H1 text="Últimas publicações" bgColor="pink" fontColor="white" />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {loading
                ? Array(5).fill(0).map((_, index) => <BlogCardSkeleton key={index} />)
                : currentPosts.map((post) => (
                    <BlogCard key={post.id} post={post} categories={categories} />
                  ))}
            </div>
            {!loading && currentPosts.length < postsToShow.length && (
              <div className="flex justify-center mt-4">
                <Button text="Carregar mais" variant="solid" onClick={loadMorePosts} />
              </div>
            )}
          </div>
          
          <div className="hidden md:block">
            <H1 text="Últimas publicações" bgColor="pink" fontColor="white" />
          </div>

          {/* Desktop View: Sidebar after Últimas publicações */}
          <div className="hidden lg:flex lg:flex-row justify-between">
            <div className="lg:w-2/3 pr-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {loading
                  ? Array(5).fill(0).map((_, index) => <BlogCardSkeleton key={index} />)
                  : currentPosts.map((post) => (
                      <BlogCard key={post.id} post={post} categories={categories} />
                    ))}
              </div>
              {!loading && currentPosts.length < postsToShow.length && (
                <div className="flex justify-center mt-4">
                  <Button text="Carregar mais" variant="solid" onClick={loadMorePosts} />
                </div>
              )}
            </div>

            <Sidebar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              handleSearch={handleSearch}
              categories={categories}
              filterByCategory={filterByCategory}
              className="lg:w-1/3 pl-4"
            />
          </div>
        </div>
      </div>

      <Newsletter />
      <Footer />
    </>
  );
};

export default Blog;