// src/components/EntrepreneurCard/EntrepreneurCard.js
import React, { useState, useEffect } from 'react';
import LocationIcon from '../../assets/ico-km-distancia.svg';
import Button from '../Button/Button';
import H3 from '../H3/H3';
import H4 from '../H4/H4';
import Avatar from '../Avatar/Avatar';

// Import the necessary Firebase functions
import { getAuth } from 'firebase/auth';
import { collection, addDoc, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

// Import your heart icons
import heartIconOver from '../../assets/ico-coracao-over.svg';
import heartIcon from '../../assets/ico-favorito.svg';

const categoryNamesWithAccent = {
  moradia: 'Moradia',
  alimentacao: 'Alimentação',
  trocas: 'Trocas',
  transporte: 'Transporte',
  documentacao: 'Documentação',
  emprego: 'Emprego',
  comunicacao: 'Comunicação',
  treinamento: 'Treinamento',
  saude: 'Saúde',
  outros: 'Outros',
};

const ensureHttps = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url;
  }
  return url;
};

// Utility to remove URLs from strings in the aboutBusiness field
const sanitizeAboutBusiness = (text) => {
  return text ? text.replace(/https?:\/\/\S+/g, '[link removido]') : '';
};

const EntrepreneurCard = ({ entrepreneur, onShowDetails }) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  const [isFavorite, setIsFavorite] = useState(false);
  const [isHeartHovered, setIsHeartHovered] = useState(false);

  useEffect(() => {
    const checkIfFavorite = async () => {
      if (currentUser) {
        const favRef = collection(db, "fav_entrepreneur");
        const q = query(favRef, where("userId", "==", currentUser.uid), where("entrepreneurId", "==", entrepreneur.id));
        const snapshot = await getDocs(q);
        setIsFavorite(!snapshot.empty);
      }
    };
    checkIfFavorite();
  }, [currentUser, entrepreneur.id]);

  const toggleFavorite = async () => {
    const favRef = collection(db, "fav_entrepreneur");
    const q = query(favRef, where("userId", "==", currentUser.uid), where("entrepreneurId", "==", entrepreneur.id));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      await deleteDoc(snapshot.docs[0].ref);
      setIsFavorite(false);
    } else {
      await addDoc(favRef, { userId: currentUser.uid, entrepreneurId: entrepreneur.id });
      setIsFavorite(true);
    }
  };

  return (
    <div className="h-450 bg-white rounded-2xl p-5 gap-4 relative">
      <H3 text={entrepreneur.companyName} textColor="purple" />
      <H4 text={categoryNamesWithAccent[entrepreneur.category]} textColor="orange" />
      <div className="flex items-center -mt-8">
        <img src={LocationIcon} alt="Location" className="w-4 h-4 mr-2" />
        <h4 className="text-base text-gray-500">{entrepreneur.businessCity} - {entrepreneur.businessState}</h4>
      </div>
      <div className="flex items-center mt-4">
        <Avatar src={entrepreneur.userAvatar} alt="User Avatar" name={entrepreneur.userName} />
        <h5 className="text-base text-gray-500">Por {entrepreneur.userName}</h5>
      </div>
      <p className="text-base mt-4 text-gray-600">{sanitizeAboutBusiness(entrepreneur.aboutBusiness)}</p>

      <div className="flex flex-col mt-4 space-y-2">
        {entrepreneur.website && (
          <a href={ensureHttps(entrepreneur.website)} className="flex items-center text-blue-800 hover:text-blue-700" target="_blank" rel="noopener noreferrer">
            <img src="/img/ico-url-perfil.svg" alt="Website Icon" className="mr-2 w-5 h-5" />
            Website
          </a>
        )}
        {entrepreneur.instagram && (
          <a href={ensureHttps(entrepreneur.instagram)} className="flex items-center text-blue-800 hover:text-blue-700" target="_blank" rel="noopener noreferrer">
            <img src="/img/ico-instagram-perfil.svg" alt="Instagram Icon" className="mr-2 w-5 h-5" />
            Instagram
          </a>
        )}
        {entrepreneur.businessPhone && (
          <a href={`tel:${entrepreneur.businessPhone}`} className="flex items-center text-blue-800 hover:text-blue-700" rel="noopener noreferrer">
            <img src="/img/ico-telefone-perfil.svg" alt="Phone Icon" className="mr-2 w-5 h-5" />
            {entrepreneur.businessPhone}
          </a>
        )}
      </div>

      <div className="absolute bottom-7 left-2.5 right-2.5 flex justify-center">
        <Button text="Ver mais" variant="transparent" onClick={() => onShowDetails(entrepreneur)} />
      </div>

      {!currentUser || currentUser.uid !== entrepreneur.userId ? (
        <button
          className="absolute top-10 right-5 w-6 h-6 flex items-center justify-center"
          onMouseEnter={() => setIsHeartHovered(true)}
          onMouseLeave={() => setIsHeartHovered(false)}
          onClick={toggleFavorite}
        >
          <img
            src={isHeartHovered || isFavorite ? heartIconOver : heartIcon}
            alt="Favoritar"
            className="w-6 h-6"
          />
        </button>
      ) : null}
    </div>
  );
};

export default EntrepreneurCard;
