import React from 'react';
import Button from './Button/Button'; 
import H1 from './H1/H1'; 
import H2 from './H2/H2';
import './SobreRedeRefugia.css'; // Import the CSS file for styling

const SobreRedeRefugia = () => {
  return (
    <div className="sobre-rede-refugia">
      <div className="sobre-content-area">
        <div className="sobre-left-block">
          <H1 text="Sobre a Rede Refugia" bgColor="purple" fontColor="white" />
          <H2 text="A Rede Refugia é mais do que uma simples plataforma; somos uma comunidade, formamos uma rede de oportunidades." textColor="white" />
          <p className="mb-5">A Rede Refugia surge em 2020 durante a pesquisa de mestrado no Programa de Engenharia de Produção da COPPE/UFRJ, realizada por Estevão Leite, sob a orientação do professor Tharcisio Fontainha. Mais especificamente, a ideia foi concebida durante a disciplina de Design de Serviços ministrada pela professora Carla Cipolla, também do Programa de Engenharia de Produção da COPPE/UFRJ.</p>
          <p className="mb-5 second-paragraph">Hoje temos uma equipe multidisciplinar de pesquisadores de diversos centros da UFRJ e estamos vinculados ao Centro de Estudos e Pesquisas em Engenharia para Desastres -Ceped Coppe.</p>
          <p className="mb-5">Nós acreditamos que pessoas migrantes e refugiadas são agentes sociais, culturais, econômicos e políticos fundamentais durante o processo de acolhimento e integração. </p>
          <p className="mb-5">A partir desse princípio, desenvolvemos uma plataforma digital para promover encontros onde as pessoas possam colaborar mutuamente. A Rede Refugia fortalece as comunidades de migrantes e refugiados no Brasil, permite que a população local, empresas, poder público e terceiro setor possam se conectar diretamente a demandas e ofertas de pessoas migrantes e refugiadas.  </p>
          <p className="mb-5">Usamos a tecnologia como uma ferramenta para tornar esses encontros mais acessíveis e expandir as oportunidades de apoio mútuo.</p>
          <p className="mb-5">Durante a nossa jornada de desenvolvimento recebemos apoio, por meios de editais, do Parque Tecnológico da UFRJ, da FAPERJ e do CNPq. Também participamos e fomos premiados no Programa Shell Iniciativa Jovem. E nossas conexões não param de crescer! </p>
          <p className="mb-5">Convidamos você a fazer parte desta rede de colaboração.</p>
          <a className="join-us-button-link mb-5" href="/criar-conta">
            <Button text="Junte-se a nós" variant="solid" />
          </a>
          <p className="mb-10">Equipe Rede Refugia!</p>
        </div>
        <div className="sobre-right-block"></div>
      </div>
    </div>
  );
};

export default SobreRedeRefugia;
