// src/components/NewsletterManagement.js
import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import Papa from 'papaparse';
import './NewsletterManagement.css';

const NewsletterManagement = () => {
  const [emails, setEmails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchEmails = async () => {
      const emailCollectionRef = collection(db, "newsletter");
      const emailData = await getDocs(emailCollectionRef);
      setEmails(emailData.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchEmails();
  }, []);

  const handleDelete = async (emailId) => {
    if (window.confirm("Você tem certeza que deseja deletar este e-mail? Esta ação é irreversível.")) {
      try {
        const emailDocRef = doc(db, "newsletter", emailId);
        await deleteDoc(emailDocRef);
        setEmails(emails.filter(email => email.id !== emailId));
      } catch (error) {
        console.error("Error deleting email: ", error);
      }
    }
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleExport = () => {
    const csv = Papa.unparse(emails);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'newsletter_emails.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredEmails = emails.filter(email =>
    email.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="newsletter-management">
      <h2>Gerenciamento de Newsletter</h2>
      <div className="controls">
        <input 
          type="text"
          placeholder="Procurar por e-mail"
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
        />
        <button className="export-button" onClick={handleExport}>Exportar e-mails</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>E-mail</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {filteredEmails.map(email => (
            <tr key={email.id}>
              <td>{email.email}</td>
              <td>
                <button className="excluir" onClick={() => handleDelete(email.id)}>Excluir</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NewsletterManagement;
