// src/components/NossoImpacto.js
import React from 'react';
import Button from './Button/Button'; // Make sure the path is correct
import H1 from './H1/H1'; 
import H2 from './H2/H2';
import './NossoImpacto.css'; // Import the CSS file for styling

const NossoImpacto = () => {
  return (
    <div className="nosso-impacto bg-blue-100 p-10">
      <div className="content-area mx-auto max-w-7xl">
        <div className="text-center">
          <H1 text="Nosso Impacto" bgColor="purple" fontColor="white" />
        </div>

        <div className="flex flex-col justify-center items-center text-center mx-auto text-2xl mt-5 mb-8 w-3/5">
          <H2 text="Nossas ações contribuem para os seguintes Objetivos do Desenvolvimento Sustentável da ONU:" textColor="purple" />
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-10">
          <div className="flex justify-center">
            <img src="/img/nossosvalores-01.png" alt="Impacto 1" style={{ width: '200px', height: '200px' }} />
          </div>
          <div className="flex justify-center">
            <img src="/img/nossosvalores-02.png" alt="Impacto 2" style={{ width: '200px', height: '200px' }} />
          </div>
          <div className="flex justify-center">
            <img src="/img/nossosvalores-03.png" alt="Impacto 3" style={{ width: '200px', height: '200px' }} />
          </div>
          <div className="flex justify-center">
            <img src="/img/nossosvalores-04.png" alt="Impacto 4" style={{ width: '200px', height: '200px' }} />
          </div>
        </div>
        <div className="flex justify-center w-full mt-20">
          <a href="/criar-conta">
            <Button text="Junte-se a nós" variant="solid" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NossoImpacto;
