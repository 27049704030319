// src/pages/Entrepreneurs.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs, doc, getDoc, limit, startAfter } from 'firebase/firestore';
import useProtectedRoute from '../hooks/useProtectedRoute';
import Menu from '../components/Menu';
import WeeklyHighlights from '../components/WeeklyHighlights';
import EntrepreneursGrid from '../components/EntrepreneursGrid';
import Footer from '../components/Footer';
import './Entrepreneurs.css';
import EntrepreneurDetailsPopup from '../components/EntrepreneurDetailsPopup';

const ITEMS_PER_PAGE = 10; // consistent with AdsWall.js

const Entrepreneurs = () => {
  const isLoggedIn = useProtectedRoute();
  const [entrepreneurs, setEntrepreneurs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const [selectedEntrepreneur, setSelectedEntrepreneur] = useState(null);

  const [authorInfo, setAuthorInfo] = useState(null);

  const fetchAuthorData = async (userId) => {
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    return userSnap.exists() ? userSnap.data() : null;
  };

  useEffect(() => {
    if (selectedEntrepreneur) {
      fetchAuthorData(selectedEntrepreneur.userId).then(data => {
        setAuthorInfo(data);
      });
    }
  }, [selectedEntrepreneur]);

  const onShowDetails = (entrepreneur) => {
    setSelectedEntrepreneur(entrepreneur);
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchEntrepreneurs();
    }
  }, [isLoggedIn]); // Ensures fetchEntrepreneurs is called only when isLoggedIn changes
  
  const fetchEntrepreneurs = async (startAfterDoc) => {
    setLoading(true);
    let q = query(
      collection(db, "entrepreneurs"),
      where("profileVisibility", "==", "1"),
      limit(ITEMS_PER_PAGE)
    );
  
    if (startAfterDoc) {
      q = query(q, startAfter(startAfterDoc));
    }
  
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const newEntrepreneurs = await Promise.all(querySnapshot.docs.map(async (docSnapshot) => {
        const entrepreneurData = docSnapshot.data();
        const userRef = doc(db, 'users', entrepreneurData.userId);
        const userSnap = await getDoc(userRef);
        return {
          id: docSnapshot.id,
          ...entrepreneurData,
          userName: userSnap.exists() ? userSnap.data().firstName : 'Unknown',
          userAvatar: userSnap.exists() ? userSnap.data().avatar : null
        };
      }));
  
      setEntrepreneurs(prev => [...prev, ...newEntrepreneurs]); // Appends new data
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastDoc);
      setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
    } else {
      setHasMore(false);
    }
  
    setLoading(false);
  };


  const loadMoreEntrepreneurs = () => {
    if (lastVisible) {
      fetchEntrepreneurs(lastVisible);
    }
  };

  return (
    <>
      <Menu isLoggedIn={isLoggedIn} />
      <WeeklyHighlights showJoinUsButton={false} />
      <div className="entrepreneurs-container">
        <EntrepreneursGrid
        entrepreneurs={entrepreneurs}
        loading={loading}
        hasMore={hasMore}
        loadMoreEntrepreneurs={loadMoreEntrepreneurs}
        onShowDetails={onShowDetails}  // Pass onShowDetails to EntrepreneursGrid
      />
      {selectedEntrepreneur && (
        <EntrepreneurDetailsPopup
          entrepreneur={selectedEntrepreneur}
          onClose={() => setSelectedEntrepreneur(null)}
          authorInfo={authorInfo}
        />
      )}
      </div>
      <Footer />
    </>
  );
};

export default Entrepreneurs;


