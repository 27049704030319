// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';

// Importing page components
import Home from './pages/Home';
import About from './pages/About';
import HowItWorks from './pages/HowItWorks';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import CreateAccount from './pages/CreateAccount';
import LoggedInHome from './pages/LoggedInHome';
import AdsWall from './pages/AdsWall';
import Entrepreneurs from './pages/Entrepreneurs';
import MySavedItems from './pages/MySavedItems';
import MySavedItemsEntrepreneurs from './pages/MySavedItemsEntrepreneurs';
import MyPublishedItems from './pages/MyPublishedItems';
import MyAccount from './pages/MyAccount';
import MessageCenter from './pages/MessageCenter';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import AdminCMS from './pages/AdminCMS';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/como-funciona" element={<HowItWorks />} />
        <Route path="/login" element={<Login />} />
        <Route path="/esqueci-a-senha" element={<ForgotPassword />} />
        <Route path="/criar-conta" element={<CreateAccount />} />
        <Route path="/home-logado" element={<LoggedInHome />} />
        <Route path="/mural-da-rede" element={<AdsWall />} />
        <Route path="/vitrine-de-empreendedores" element={<Entrepreneurs />} />
        <Route path="/meus-itens-salvos" element={<MySavedItems />} />
        <Route path="/meus-itens-salvos-empreendedores" element={<MySavedItemsEntrepreneurs />} />
        <Route path="/meus-itens-publicados" element={<MyPublishedItems />} />
        <Route path="/minha-conta" element={<MyAccount />} />
        <Route path="/central-de-mensagens" element={<MessageCenter />} />
        <Route path="/blog" element={<Blog />} />
        {/* Assuming BlogPost component will handle fetching the post based on URL parameter */}
        <Route path="/blog/:postId" element={<BlogPost />} />
        <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
        <Route path="/termos-de-uso" element={<TermsOfUse />} />
        <Route path="/0236116f-8927-4b6e-b74b-a8da64026eb8" element={<AdminCMS />} />
      </Routes>
    </Router>
  );
}

export default App;
