// src/components/ForgotPasswordForm.js
import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import Button from './Button/Button';
import H2 from './H2/H2';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({ text: '', type: '' });
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage({ 
        text: 'Um e-mail de redefinição foi enviado para o endereço cadastrado na plataforma. Siga os passos para trocar sua senha e efetue novamente o login.', 
        type: 'success' 
      });
      setEmailSent(true);
    } catch (error) {
      let errorMessage = 'Erro ao enviar e-mail. Tente novamente.';
      if (error.code === 'auth/invalid-email') {
        errorMessage = 'E-mail inválido.';
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = 'Usuário não encontrado.';
      }
      setMessage({ text: errorMessage, type: 'error' });
      setEmailSent(false);
    }
  };

  const messageClass = message.type === 'success' ? 'text-green-600' : 'text-red-600';

  return (
    <div className="flex justify-center items-center bg-blue-600 bg-cover bg-no-repeat bg-center" style={{ backgroundImage: "url(/img/bg-cadastro.svg)" }}>
      <div className="bg-white rounded-xl mt-40 mb-40 p-8">
        <form onSubmit={handleSubmit} className="space-y-6">
          <H2 text="Redefinir sua senha" textColor="purple" />
          {!emailSent && (
            <p className="text-lg text-gray-700">
              Digite seu e-mail e enviaremos um link para redefinir sua senha.
            </p>
          )}
          {!emailSent && (
            <div className="field">
              <label htmlFor="email" className="block text-lg font-medium text-gray-700">E-mail</label>
              <input 
                type="email" 
                id="email"
                className="w-full px-4 py-2 border border-gray-300 rounded-xl text-lg"
                value={email} 
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          )}
          {message.text && (
            <p className={`${messageClass} text-lg font-medium`}>{message.text}</p>
          )}
          {!emailSent && (
            <div className="flex justify-end">
              <Button text="Enviar e-mail" variant="transparent" type="submit" />
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
