import React from 'react';
import Button from './Button/Button'; // Adjust the path as per your directory structure
import H1 from './H1/H1'; 
import H2 from './H2/H2';
import './FacaParte.css'; // Import the CSS file for styling

const FacaParte = () => {
  return (
    <div className='faca-parte'>
      <div className='content-area'>
        <H1 text="Faça Parte da Nossa Rede" bgColor="purple" fontColor="white" />
        <div className='title-area'>
          <div className="flex justify-center md:w-3/5 mx-auto">
            <H2 text="A Rede Refugia aceita dois tipos de perfis e é composta por ofertas e demandas criadas pelos usuários." textColor="purple" />
          </div>
        </div>
        <div className='individual-section'>
          <div className='f-image-container'>
            <img src="/img/facaparte-homem.png" width="554" height="580" alt="Homem sorrindo de boné" /> 
          </div>
          <div className='text-container'>
          	<img src="/img/ico-pessoa.png" alt="Pessoa Física" className="sm:mb-4 md:mb-2.5" /> 
            <H2 text="Pessoa Física" textColor="purple" />
            <p className="sm:mt-0 md:-mt-5">Se você é uma pessoa migrante ou refugiada, ou uma pessoa apoiadora, <a className="font-bold text-pink-refugia" href="/criar-conta">inscreva-se agora</a> para acessar a Rede Refugia. Aqui você pode divulgar o que tem a oferecer, se conectar com outros migrantes e com a população local e encontrar oportunidades para facilitar seu acolhimento e integração no Brasil.</p>

            <a className="join-us-button-link" href="/criar-conta">
              <Button text="Junte-se a nós" variant="solid" />
            </a>
          </div>
        </div>
        <div className='mirrored-section'>
          <div className='text-container'>
          	<img src="/img/ico-organizacao.png" alt="Organização" className="sm:mb-4 md:mb-2.5" /> 
            <H2 text="Organização" textColor="purple" />
            <p className="sm:mt-0 md:-mt-5">Se você é representante de uma organização que atua ou deseja atuar com pessoas migrantes e refugiadas, inscreva-se agora para acessar a Rede Refugia. Aqui você pode encontrar serviços e profissionais migrantes e refugiados e se conectar com outras organizações. </p>
            <a className="join-us-button-link" href="/criar-conta">
              <Button text="Junte-se a nós" variant="solid" />
            </a>
          </div>
          <div className='f-image-container'>
            <img src="/img/facaparte-mulher.png" width="554" height="580" alt="Duas mulheres em reunião de negócios" /> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacaParte;
