import React from 'react';
import Button from './Button/Button'; 
import H1 from './H1/H1';
import H2 from './H2/H2';
import './AgoraEComVoce.css'; // Import the CSS file for styling

const AgoraEComVoce = () => {
  return (
    <div className='agora-e-com-voce'>
      <div className='content-area'>
      	<div className='title-area'>
          <div className="flex justify-center md:w-4/12 mx-auto">
            <H1 text="Agora é com você!" textColor="white" />
          </div>
          <div className="flex justify-center md:w-2/6 mx-auto">
            <H2 text="Vem com a gente nessa?" textColor="white" />
          </div>
        	<div className="flex justify-center w-full">
            <a href="/criar-conta" className="w-full md:w-auto flex justify-center">
              <Button text="Junte-se a nós" variant="solid" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgoraEComVoce;
